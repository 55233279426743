<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="logo-wrapper">
            <img src="@/assets/images/logo-micronutrientes-horizontal.png">
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="menu">
            <router-link to="/modulos" class="btn-inicio"><img src="@/assets/images/icon_home.png"></router-link>
            <router-link to="/perfil" class="btn-profile"><img src="@/assets/images/icon_profile.png"></router-link>
            <router-link to="/logout" class="btn-logout"><img src="@/assets/images/icon_sesion.png"></router-link>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="linea"></div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-lg-12">
          <div class="perfil_wrapper d-xl-flex d-md-flex d-none">
            <div class="icono">
              <img src="@/assets/images/ico_perfil_data.png">
            </div>
            <div class="data">
              <h3>Perfil de usuario</h3>
              <p class="nombre">Nombre: <span>{{ nombre }}</span></p>
              <p class="apellidos">Apellidos: <span>{{ apellido_paterno }} {{ apellido_materno }}</span></p>
              <p class="apellidos">Correo electrónico: <span>{{ email }}</span></p>

            </div>
            <div class="action">
              <router-link to="/editar_perfil" class="btn-iniciar">Editar perfil</router-link>
            </div>
          </div>

          <div class="perfil_wrapper_mobile  d-xl-none d-md-none d-block">
            <div class="icono">
              <img src="@/assets/images/ico_perfil_data_mobile.png">
            </div>
            <div class="data">
              <h3>Perfil de usuario</h3>
              <p class="nombre">Nombre: <span>{{ nombre }}</span></p>
              <p class="apellidos">Apellidos: <span>{{ apellido_paterno }} {{ apellido_materno }}</span></p>
              <p class="apellidos">Correo electrónico: <span>{{ email }}</span></p>

            </div>
            <div class="action">
              <router-link to="/editar_perfil" class="btn-iniciar">Editar perfil</router-link>
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="perfil_wrapper d-xl-flex d-md-flex d-none">
            <div class="icono">
              <img src="@/assets/images/ico_perfil_progreso.png">
            </div>
            <div class="data">
              <h3>Progreso del curso</h3>
              <p class="nombre">Total a realizar: <span>10 cursos</span></p>
              <p class="apellidos">Cursos finalizados: <span>{{ completados }} de 10</span></p>
              <p class="apellidos">Total de tiempo: <span>5 horas</span></p>

            </div>
            <div class="action">
              <div class="progreso">
                <RadialProgress :diameter="170" :completed-steps="completados" :total-steps="10" :strokeWidth="15" :innerStrokeWidth="15" :innerStrokeColor="'#1974b9'" :startColor="'#2aa7de'" :stopColor="'#2aa7de'"><p class="porcentaje_progreso">{{ completados * 10 }} %</p></RadialProgress>
              </div>
            </div>
          </div>

          <div class="perfil_wrapper_mobile d-xl-none d-md-none d-block">
            <div class="icono">
              <img src="@/assets/images/ico_perfil_progreso_mobile.png">
            </div>
            <div class="data">
              <h3>Progreso del curso</h3>
              <p class="nombre">Total a realizar: <span>10 cursos</span></p>
              <p class="apellidos">Cursos finalizados: <span>{{ completados }} de 10</span></p>
              <p class="apellidos">Total de tiempo: <span>5 horas</span></p>

            </div>
            <div class="action">
              <div class="progreso">
                <RadialProgress :diameter="170" :completed-steps="completados" :total-steps="10" :strokeWidth="15" :innerStrokeWidth="15" :innerStrokeColor="'#1974b9'" :startColor="'#2aa7de'" :stopColor="'#2aa7de'"><p class="porcentaje_progreso">{{ completados * 10 }} %</p></RadialProgress>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <footer>
            <div class="row">
              <div class="col-lg-6 col-8">
                <p>Copyright &copy; Bayer AG </p>
              </div>
              <div class="col-lg-6 col-4" style=" text-align: right;">
                <div class="logof"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>  
  </div>

  <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
</template>

<style scoped>

  .progreso {
    padding: 0 50px;
  }

  .progreso .porcentaje_progreso {
    font-size: 40px;
    color: #1974b9;
    padding: 0;
    margin: 0;
  }

  div.perfil_wrapper {
    width: 100%;
    background: rgba(248, 236, 236, 0.20);
    padding: 30px;
    display: flex;
    flex-flow: row;
    margin: 15px 0;
  }
 
  div.perfil_wrapper .icono {
    flex: 0 0 auto;
    padding: 15px 30px 0 0;
  }

  div.perfil_wrapper .data {
    flex: 1 1 auto;
  }

  div.perfil_wrapper .data h3 {
    color: #036;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin: 0 0 10px 0;
    padding: 0;
  }

  div.perfil_wrapper .data p {
    color: #5C5C5C;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin: 0 0 10px 0;
    padding: 0;
  }

  div.perfil_wrapper .data p span {
    color: #0093D0;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  div.perfil_wrapper .action {
    flex: 0 0 auto;
  }

  /* --------------------------------------------------------- */

  
  div.perfil_wrapper_mobile {
    width: 100%;
    background: rgba(248, 236, 236, 0.20);
    padding: 30px;
    display: block;
    margin: 15px 0;
  }
 
  div.perfil_wrapper_mobile .icono {
    float: left;
    padding: 0px 15px 0 0;
  }

  div.perfil_wrapper_mobile .data {
    float: left;
    width: 200px;
  }

  div.perfil_wrapper_mobile .data h3 {
    color: #036;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin: 0 0 10px 0;
    padding: 0;
  }

  div.perfil_wrapper_mobile .data p {
    color: #5C5C5C;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin: 0 0 10px 0;
    padding: 0;
  }

  div.perfil_wrapper_mobile .data p span {
    color: #0093D0;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  div.perfil_wrapper_mobile .action {
    float: none;
    clear: both;
  }

   /* --------------------------------------------------------- */

  div.menu {
    text-align: center;
    width: 100%;
    margin: 25px 0 0 0;
    display: flex;
    flex-flow: row;
  }

  div.menu a {
    height: 60px;
    line-height: 60px;
    flex: 1;
    text-align: center;
    display: block;
    width: 33%;
    flex-grow: 1;
  }

  div.menu a.btn-profile {
    margin: 0;
  }

  div.menu a.btn-logout {
      
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: center;
    padding: 15px ;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

  div.linea {
    background: #C5C3C3;
    height: 2px;
    width: 100%;
    margin: 0;
  }

  .home .logo {
    margin: 30px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 20px;
    color: #10384f;
    padding: 30px 0;
    font-weight: bold;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61bb47;
    padding: 15px 0;
  }

  .home .btn-start {
    border: 0;
    background-color: #fff;
    margin: 15px auto 0 auto;
  }

  .btn-iniciar {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 30px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  .btn-iniciar:hover {
    border: 2px solid #0093D0;
    background: #FFF;
    color: #0093D0;
  }


  footer {
    width: 100%;
    height: 58px;
    background: #61BB47;
  }


  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    padding: 0 0 0 15px;
  }

  footer img {
    margin: 7px 25px 0 0;
  }

  footer .logof {
    width: 42px;
    height: 43px;
    background-image: url('@/assets/images/logo_footer_small.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 7px 25px 0 0;
    padding: 0;
    float: right;
  }
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
  
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  

  div.menu {
    text-align: right;
    width: 100%;
    margin: 15px 0;
    display: block;
  }

  div.menu a {
    height: 60px;
    display: inline-block;
    line-height: 60px;
    width: auto;
  }

  div.menu a.btn-profile {
    margin: 0 60px;
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 0;
  }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    
}

/* Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {  
  .home .logo {
    margin: 15px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 20px;
    color: #000;
    padding: 15px 0;
    font-weight: bold;
    margin: 0;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61BB47;
    padding: 15px 0;
    margin: 0;
  }

  footer {
    width: 100%;
    height: 88px;
    background: #61BB47;
  }
  
  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 88px;
    text-align: left;
    padding: 0 0 0 134px;
    margin: 0;
  }

  footer img {
    margin: 10px 128px 0 0;
  }

  footer .logof {
      width: 63px;
      height: 63px;
      background-image: url('@/assets/images/logo_footer_big.png');
      background-position: center center;
      background-repeat: no-repeat;
      margin: 10px 128px 0 0;
      padding: 0;
      float: right;
    }
    
  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

}  

</style>

<script>
// @ is an alias to /src

import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

import RadialProgress from "vue3-radial-progress";
import ElearningDataService from '../services/ElearningDataService';

export default {
  name: 'PerfilView',
  components: {
    Loading,
    RadialProgress
  },

  data: () => ({
    isLoading: false,
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
    email: '',
    cursos_finalizados: 0,
    completados: 0
  }),
  mounted(){
    this.isLoading = true;
    this.uid = localStorage.uid;

    let data = new FormData;

    data.append('uid', localStorage.uid);

    ElearningDataService.perfil(data).then(response => {
      this.isLoading = false;
      
      if(!response.data.error){
        var perfil = response.data.userinfo;

        this.nombre = perfil.nombre;
        this.apellido_paterno = perfil.apellido_paterno;
        this.apellido_materno = perfil.apellido_materno;
        this.email = perfil.email;
        this.completados = perfil.completados;
      }
            
    }).catch(e => {
      console.log(e);
    });
  },
  methods: {
     
    
  }
}



</script>
