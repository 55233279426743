<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="logo-wrapper">
            <img src="@/assets/images/logo-micronutrientes-horizontal.png">
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="menu">
            <router-link to="/modulos" class="btn-inicio"><img src="@/assets/images/icon_home.png"></router-link>
            <router-link to="/perfil" class="btn-profile"><img src="@/assets/images/icon_profile.png"></router-link>
            <router-link to="/logout" class="btn-logout"><img src="@/assets/images/icon_sesion.png"></router-link>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="linea"></div>
        </div>
      </div>
      
      <div class="row">
       
       <div class="col-lg-12" style="background-color: #fff;"> 
         <div style="text-align: center;">
          
           <div class="formulario">
             <p class="formulario-titulo">Información de perfil de usuario</p>
             <div class="mb-3">
               <input type="text" class="form-control input-nombre" :class="{ error: v$.nombre.$errors.length, success: v$.nombre.$errors.length == 0 && validado }"  id="nombre" aria-describedby="emailHelp" placeholder="Nombre" v-model="nombre">
             </div>

             <div class="mb-3">
               <input type="text" class="form-control input-apellido_paterno" :class="{ error: v$.apellido_paterno.$errors.length, success: v$.apellido_paterno.$errors.length == 0 && validado }"  id="apellido_paterno" aria-describedby="emailHelp" placeholder="Apellido paterno" v-model="apellido_paterno">
             </div>

             <div class="mb-3">
               <input type="text" class="form-control input-apellido_materno" :class="{ error: v$.apellido_materno.$errors.length, success: v$.apellido_materno.$errors.length == 0 && validado }"  id="apellido_materno" aria-describedby="emailHelp" placeholder="Apellido materno" v-model="apellido_materno">
             </div>

             <div class="mb-3">
               <input type="email" class="form-control input-email" :class="{ error: v$.email.$errors.length, success: v$.email.$errors.length == 0 && validado }"  id="email" aria-describedby="emailHelp" placeholder="Correo electrónico" v-model="email">
             </div>

             <div class="mb-3">
               <input type="text" class="form-control input-cedula" :class="{ error: v$.cedula.$errors.length, success: v$.cedula.$errors.length == 0 && validado }"  id="cedula" aria-describedby="emailHelp" placeholder="Cédula profesional" v-model="cedula">
             </div>

             <div class="mb-3">
               <select class="form-select input-estado" :class="{ error: v$.estado.$errors.length, success: v$.estado.$errors.length == 0 && validado }"  id="estado" aria-describedby="emailHelp" placeholder="Seleccione su estado" v-model="estado">
                 <option value="">Seleccione su estado</option>
                 <option value="Aguascalientes">Aguascalientes</option>
                 <option value="Baja California">Baja California</option>
                 <option value="Baja California Sur">Baja California Sur</option>
                 <option value="Campeche">Campeche</option>
                 <option value="Chiapas">Chiapas</option>
                 <option value="Chihuahua">Chihuahua</option>
                 <option value="Ciudad de México">Ciudad de México</option>
                 <option value="Coahuila">Coahuila</option>
                 <option value="Colima">Colima</option>
                 <option value="Durango">Durango</option>
                 <option value="Estado de México">Estado de México</option>
                 <option value="Guanajuato">Guanajuato</option>
                 <option value="Guerrero">Guerrero</option>
                 <option value="Hidalgo">Hidalgo</option>
                 <option value="Jalisco">Jalisco</option>
                 <option value="Michoacán">Michoacán</option>
                 <option value="Morelos">Morelos</option>
                 <option value="Nayarit">Nayarit</option>
                 <option value="Nuevo León">Nuevo León</option>
                 <option value="Oaxaca">Oaxaca</option>
                 <option value="Puebla">Puebla</option>
                 <option value="Querétaro">Querétaro</option>
                 <option value="Quintana Roo">Quintana Roo</option>
                 <option value="San Luis Potosí">San Luis Potosí</option>
                 <option value="Sinaloa">Sinaloa</option>
                 <option value="Sonora">Sonora</option>
                 <option value="Tabasco">Tabasco</option>
                 <option value="Tamaulipas">Tamaulipas</option>
                 <option value="Tlaxcala">Tlaxcala</option>
                 <option value="Veracruz">Veracruz</option>
                 <option value="Yucatán">Yucatán</option>
                 <option value="Zacatecas">Zacatecas</option>
               </select>
             </div>

             <div class="mb-3">
               <div class="password-wrapper">
                 <input :type="passwordFieldType" class="form-control input-password" id="password" aria-describedby="emailHelp" placeholder="Contraseña" v-model="password">
                 <button class="password_visibility_changer" @click="cambiarModoPassword" :class="{ on: modoPassword, off: !modoPassword }"></button>
               </div>
             </div>

             <button class="btn-login" @click="submitForm">Guardar nuevos datos</button>
           </div>
         </div>
       </div>
     </div>

     <div class="row">
        <div class="col-lg-12" style="">
          <footer>
            <div class="row">
              <div class="col-lg-6 col-8" style="">
                <p>Copyright &copy; Bayer AG </p>
              </div>
              <div class="col-lg-6 col-4" style=" text-align: right;">
                <div class="logof"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>  
  </div>

  <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
</template>

<style scoped>

  .formulario {
    width: 320px;
    margin: 45px auto;
    border-radius: 6px;
    padding: 0px;
  }

  .formulario p.formulario-titulo {
    color: #036;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin: 0 0 30px 0;
  }

  .formulario input[type='email'], .formulario input[type='password'], .formulario input[type='text'] {
    width: 320px;
    height: 48px;
    border-radius: 5px;
    background: #F4F4F4;
    border: 2px solid #F4F4F4;
  }

  .formulario input[type='email'] {
    background-image: url('@/assets/images/icon_email.svg');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario input.input-password {
    background-image: url('@/assets/images/icon_key.svg');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario input.input-nombre {
    background-image: url('@/assets/images/icon_nombre.png');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario input.input-apellido_paterno, .formulario input.input-apellido_materno {
    background-image: url('@/assets/images/icon_apellidos.png');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario input.input-cedula {
    background-image: url('@/assets/images/icon_cedula.png');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario select.input-estado {
    width: 320px;
    height: 48px;
    border-radius: 5px;
    background: #F4F4F4;
    border: 2px solid #F4F4F4;
    background-image: url('@/assets/images/icon_estado.png');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario select.form-select:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 100%;
    line-height: 38px;
    content: "∨";
    text-align: center;
    color: #714BB9;
    font-size: 24px;
    border-left: 1px solid #3C1C78;
    z-index: -1;
  } 

  .formulario .form-check {
    text-align: left;
  }

  .formulario .form-check label {
    color: #747474;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    vertical-align: text-top;
    padding: 0 0 0 15px;
  }

  .formulario .password-wrapper {
    width: 100%;
    position: relative;
  }

  .formulario .password-wrapper .password_visibility_changer {
    position: absolute;
    width: 48px;
    height: 48px;
    background-color: transparent;
    top: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center center;
    border: 0;
  }

  .formulario .password-wrapper .password_visibility_changer.on {
    background-image: url('@/assets/images/icon_eye_open.png');
  }

  .formulario .password-wrapper .password_visibility_changer.off {
    background-image: url('@/assets/images/icon_eye_close.png');
  }

  .formulario input[type='checkbox'] {
    width: 28px;
    height: 28px;
  }

  .formulario .recaptcha_wrapper {
    width: 100%;
    border-radius: 5px;
  }
  .formulario input.error, .formulario select.error, .formulario .recaptcha_wrapper.error {
    border: 2px solid #F41313;
  }
  
  .formulario p.error_recaptcha {
    color: #F41313;
    font-size: 12px;
    padding: 5px 0 0 0;
    text-align: center;
    font-style: italic;
  }

  .formulario input.error::placeholder {
    color: #F41313;
    font-style: italic;
  }

  .formulario input.success, .formulario select.success, .formulario .recaptcha_wrapper.success {
    border: 2px solid #61BB47;
  }

  .formulario input.success::placeholder {
    color: #61BB47;
  }

  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 45px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #0093D0;
  }

  .btn-login:hover {
    border: 2px solid #0093D0;
    background: #FFF;
    color: #0093D0;
  }

  div.menu {
    text-align: center;
    width: 100%;
    margin: 25px 0 0 0;
    display: flex;
    flex-flow: row;
  }

  div.menu a {
    height: 60px;
    line-height: 60px;
    flex: 1;
    text-align: center;
    display: block;
    width: 33%;
    flex-grow: 1;
  }

  div.menu a.btn-profile {
    margin: 0;
  }

  div.menu a.btn-logout {
      
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: center;
    padding: 15px ;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

  div.linea {
    background: #C5C3C3;
    height: 2px;
    width: 100%;
    margin: 0;
  }

  .home .logo {
    margin: 30px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 20px;
    color: #10384f;
    padding: 30px 0;
    font-weight: bold;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61bb47;
    padding: 15px 0;
  }

  .home .btn-start {
    border: 0;
    background-color: #fff;
    margin: 15px auto 0 auto;
  }

  footer {
    width: 100%;
    height: 58px;
    background: #61BB47;
  }

  
  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    padding: 0 0 0 15px;
    margin: 0;
  }

  footer img {
    margin: 7px 25px 0 0;
  }

  footer .logof {
    width: 42px;
    height: 43px;
    background-image: url('@/assets/images/logo_footer_small.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 7px 25px 0 0;
    padding: 0;
    float: right;
  }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
  
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  

  div.menu {
    text-align: right;
    width: 100%;
    margin: 15px 0;
    display: block;
  }

  div.menu a {
    height: 60px;
    display: inline-block;
    line-height: 60px;
    width: auto;
  }

  div.menu a.btn-profile {
    margin: 0 60px;
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 0;
  }

  
  .wrapper_puntaje {
  width: 640px;
  height: 193px;
  border-radius: 6px;
  border: 1px solid #0093D0;
  background: #FFF;
  margin: 15px auto 40px auto;
}

.wrapper_puntaje .header {
  width: 608px;
  height: 46px;
  border-radius: 6px;
  background: #E2EAFF;
  margin: 15px auto;
  padding: 0;
}

.wrapper_puntaje .header .puntaje {
  color: #61BB47;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
}

.wrapper_puntaje .header .puntaje span {
  color: #747474;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.wrapper_puntaje .descripcion {
  color: #5C5C5C;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin: 20px 0 0 0;
}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    
}

/* Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {  
  .home .logo {
    margin: 15px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 20px;
    color: #000;
    padding: 15px 0;
    font-weight: bold;
    margin: 0;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61BB47;
    padding: 15px 0;
    margin: 0;
  }

  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 15px auto 45px auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  footer {
    width: 100%;
    height: 88px;
    background: #61BB47;
  }

  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 88px;
    text-align: left;
    padding: 0 0 0 134px;
    margin: 0;
  }

  footer img {
    margin: 10px 128px 0 0;
  }

  footer .logof {
      width: 63px;
      height: 63px;
      background-image: url('@/assets/images/logo_footer_big.png');
      background-position: center center;
      background-repeat: no-repeat;
      margin: 10px 128px 0 0;
      padding: 0;
      float: right;
    }
    
  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

}  
</style>

<script>
// @ is an alias to /src
import useValidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

import ElearningDataService from '../services/ElearningDataService';

export default {
  name: 'EditarperfilView',
  components: {
    Loading,
  },

  data: () => ({
    isLoading: false,
    cursos_finalizados: 0,
    completados: 0,
    v$: useValidate(),
    validado: false,
    modoPassword: false,
    passwordFieldType: 'password',
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
    email: '',
    cedula: '',
    estado: '',
    password: '',
    recaptchaValidado: false,
    showRecaptcha: true,
		loadingTimeout: 30000 // 30 seconds
  }),
  mounted(){
    this.isLoading = true;
    this.uid = localStorage.uid;

    let data = new FormData;

    data.append('uid', localStorage.uid);

    ElearningDataService.perfilcompleto(data).then(response => {
      this.isLoading = false;
      
      if(!response.data.error){
        var perfil = response.data.userinfo;

        this.nombre = perfil.nombre;
        this.apellido_paterno = perfil.apellido_paterno;
        this.apellido_materno = perfil.apellido_materno;
        this.email = perfil.email;
        this.cedula = perfil.cedula;
        this.estado = perfil.estado;

      }
            
    }).catch(e => {
      console.log(e);
    });
  },
  methods: {
     
    cambiarModoPassword() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.modoPassword = !this.modoPassword;
    },
    submitForm() {
      this.v$.$validate() // checks all inputs
      this.validado = true;
      if (!this.v$.$error) {
        this.isLoading = true;

        console.log("Actualizando perfil de usuario."); 

        let data = new FormData;

        data.append('uid', localStorage.uid);
        data.append('nombre', this.nombre);
        data.append('apellido_paterno', this.apellido_paterno);
        data.append('apellido_materno', this.apellido_materno);
        data.append('email', this.email);
        data.append('cedula', this.cedula);
        data.append('estado', this.estado);
        data.append('password', this.password);

        ElearningDataService.actulizarPerfil(data).then(response => {
            
            let error = response.data.error;

            if(error){
              this.$router.push('/editar_perfil');
            }else{

              localStorage.nombre = this.nombre;
              localStorage.apellido_paterno = this.apellido_paterno;
              localStorage.apellido_materno = this.apellido_materno;
              localStorage.email = this.email;
              localStorage.cedula = this.cedula;
              localStorage.estado = this.estado;
              localStorage.loggedin = true;
              //localStorage.uid = response.data.uid; 

              this.$router.push('/perfil');
            }

            this.isLoading = false;
                        
          })
          .catch(e => {
            console.log(e);
          });

      } else {
        //alert('Form failed validation')
      }
    },
   
  },
  validations() {
    return {
      nombre: { required },
      apellido_paterno: { required },
      apellido_materno: { required },
      email: { required, email },
      cedula: { required },
      estado: { required },
    }
  },

}



</script>
