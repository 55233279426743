<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="logo-wrapper">
            <img src="@/assets/images/logo-micronutrientes-horizontal.png">
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="menu">
            <router-link to="/modulos" class="btn-inicio"><img src="@/assets/images/icon_home.png"></router-link>
            <router-link to="/perfil" class="btn-profile"><img src="@/assets/images/icon_profile.png"></router-link>
            <router-link to="/logout" class="btn-logout"><img src="@/assets/images/icon_sesion.png"></router-link>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="linea"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="header">
            <h1>Contenido del curso</h1>
            <h2 class="d-xl-block d-md-none d-none">Para obtener la aprobación debe leer y completar cada módulo. Se necesita aprobar el módulo previo para avanzar en el curso.</h2>
            <h2 class="d-xl-none d-md-block d-block">Para obtener la aprobación debe leer y completar cada módulo. Se necesita aprobar el módulo previo para avanzar en el curso.</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <div v-for="(curso, index) in cursos" :key="curso.id">
          <div  class="curso" v-if="curso.id>0">
            <div class="curso_titulo">
              <p><strong>MÓDULO {{ index }}</strong></p>
              <p><u>{{ curso.titulo }}</u></p>
            </div>
            <div class="curso_wraper" v-if="curso.id>0">
              <div class="imagen">
                <div class="imagen_wraper">
                  <div v-if="curso.terminado && !curso.disponible" class="icon-completado"><img src="@/assets/images/icon_curso_completado.png"></div>
                  <div v-if="!curso.terminado && curso.disponible" class="icon-iniciar"><img src="@/assets/images/icon_curso_iniciar.png"></div>
                  <div v-if="!curso.terminado && !curso.disponible" class="icon-pendiente"><img src="@/assets/images/icon_curso_pendiente.png"></div>
                  <img :src="curso.imagen">
                </div>
              </div>
              <div class="descripcion">
                <p>{{ curso.descripcion }}</p>
              </div>
              <div class="boton">
                <router-link to="/modulos" v-if="curso.terminado && !curso.disponible" class="btn-completado">Completado</router-link>
                <router-link :to="{name: 'modulo', params: {uid: uid, nModulo: curso.id}}" v-if="!curso.terminado && curso.disponible" class="btn-iniciar">Iniciar curso</router-link>
                <router-link to="/modulos" v-if="!curso.terminado && !curso.disponible" class="btn-pendiente"><img src="@/assets/images/icon_lock.png">Curso pendiente</router-link>
              </div>
            </div>
            
          </div>
        </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <footer>
            <div class="row">
              <div class="col-lg-6 col-8" style="">
                <p>Copyright &copy; Bayer AG </p>
              </div>
              <div class="col-lg-6 col-4" style=" text-align: right;">
                <div class="logof"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>  
  </div>

  <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
</template>

<style scoped>

  div.curso {
    width: 100%;
    padding: 18px;
    background: rgba(248, 236, 236, 0.20);
    margin: 0 0 15px 0;
    text-align: left;
  }

  div.curso .curso_titulo {
    width: 100%;
  }

  div.curso .curso_titulo p {
    width: 100%;
    color: #036;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  div.curso .curso_titulo p span {
    color: #036;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  div.curso .curso_wraper {
    width: 100%;
    display: flex;
    flex-flow: column;
    margin: 15px 0;
  }

  div.curso .curso_wraper .imagen {
    flex: 1;
  }

  div.curso .curso_wraper .imagen .imagen_wraper {
    position: relative;
    height: 84px;
    width: 133px;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 0 15px 0;
  }

  div.curso .curso_wraper .imagen .imagen_wraper div {
    position: absolute;
    top: 19px;
    left: 44px;
    z-index: 10;
  }

  div.curso .curso_wraper .imagen .imagen_wraper img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  div.curso .curso_wraper .imagen .imagen_wraper div img {
    position: relative;
  }

  div.curso .curso_wraper .descripcion {
    flex: 5;
  }

  div.curso .curso_wraper .descripcion p {
    color: #5C5C5C;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 0 20px 0 0;
  }

  div.curso .curso_wraper .boton {
    flex: 1;
  }
 
  div.menu {
    text-align: center;
    width: 100%;
    margin: 25px 0 0 0;
    display: flex;
    flex-flow: row;
  }

  div.menu a {
    height: 60px;
    line-height: 60px;
    flex: 1;
    text-align: center;
    display: block;
    width: 33%;
    flex-grow: 1;
  }

  div.menu a.btn-profile {
    margin: 0;
  }

  div.menu a.btn-logout {
    
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: center;
    padding: 15px ;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

  div.linea {
    background: #C5C3C3;
    height: 2px;
    width: 100%;
    margin: 0;
  }

  div.header {
    background: #0093D0;
    height: auto;
    width: 100%;
    margin: 14px 0;
    padding: 14px;
  }

  div.header h1 {
    color: #FFF;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
  }

  div.header h2 {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin: 0;
  }

  .home .logo {
    margin: 30px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 20px;
    color: #10384f;
    padding: 30px 0;
    font-weight: bold;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61bb47;
    padding: 15px 0;
  }

  .home .btn-start {
    border: 0;
    background-color: #fff;
    margin: 15px auto 0 auto;
  }

  ul.app-instructions {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    list-style: none;
  }

  ul.app-instructions li p{
    text-align: left;
  }

  .app-cedula {
    width: 100%;
    background-color: #ededed;
    height: 59px;
    line-height: 59px;
    border-radius: 15px;
    border-color: transparent;
  }

  .vcp-container, .vcp-container video {
    width: 100%;
    height: 100%;
    background-color: #7bbc6e;
  }

  .vcp-dashboard {
    width: 100% !important;
    margin-left: 0 !important;
  }

  button.app-registro {
    width: 100%;
    height: 59px;
    line-height: 59px;
    background-color: #fff;
    color: #10384f;
    font-size: 22px;
    text-align: center;
    border-radius: 15px;
    border-color: transparent;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.54); 
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.54);
    margin: 30px 0 0 0;
  }

  button.app-registro span svg {
    margin: -5px 0 0 0;
    font-size: 26px;
  }

  .btn-completado {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #8E8E8E;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 0px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  .btn-completado:hover {
    border: 2px solid #8E8E8E;
    background: #FFF;
    color: #8E8E8E;
  }

  .btn-iniciar {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 0px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  .btn-iniciar:hover {
    border: 2px solid #0093D0;
    background: #FFF;
    color: #0093D0;
  }
 
  .btn-pendiente {
    display: block;
    width: 300px;
    height: 48px;
    padding: 0px;
    border-radius: 30px;
    background: #EAE7E4;
    text-align: center;
    color: #0093D0;
    border: 0;
    margin: 0px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
    line-height: 48px;
    position: relative;
  }

  .btn-pendiente img {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: -5px 5px 0 0;
  }

  .btn-pendiente:hover {
    border: 2px solid #EAE7E4;
    background: #FFF;
    color: #0093D0;
  }

  footer {
    width: 100%;
    height: 58px;
    background: #61BB47;
  }

  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    padding: 0 0 0 15px;
    margin: 0;
  }

  footer img {
    margin: 7px 25px 0 0;
  }

  footer .logof {
    width: 42px;
    height: 43px;
    background-image: url('@/assets/images/logo_footer_small.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 7px 25px 0 0;
    padding: 0;
    float: right;
  }
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {  
  
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  .formulario {
    width: 360px;
    margin: 45px auto;
    background: #EAE7E4;
    border-radius: 6px;
    padding: 30px;
  }

  .player-wrapper {
    height: 440px;
    padding: 15px;
  }

  ul.app-instructions li p{
    font-size:16px; 
    color:#5C5C5C;
    padding: 0 0 15px 0;
  }

  div.menu {
    text-align: right;
    width: 100%;
    margin: 15px 0;
    display: block;
  }

  div.menu a {
    height: 60px;
    display: inline-block;
    line-height: 60px;
    width: auto;
  }

  div.menu a.btn-profile {
    margin: 0 60px;
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 0;
  }

  
  div.curso {
    max-width: 640px;
    padding: 18px;
    background: rgba(248, 236, 236, 0.20);
    margin: 0 auto 15px auto;
    text-align: left;
  }

  div.curso .curso_titulo {
    width: 100%;
  }

  div.curso .curso_titulo p {
    width: 100%;
    color: #036;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  div.curso .curso_titulo p span {
    color: #036;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  div.curso .curso_wraper {
    width: 100%;
    display: flex;
    flex-flow: column;
    margin: 15px 0;
  }

  div.curso .curso_wraper .imagen {
    flex: 1;
  }

  div.curso .curso_wraper .imagen .imagen_wraper {
    position: relative;
  }

  div.curso .curso_wraper .imagen .imagen_wraper div {
    position: absolute;
    top: 19px;
    left: 44px;
    z-index: 10;
  }

  div.curso .curso_wraper .imagen .imagen_wraper img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  div.curso .curso_wraper .imagen .imagen_wraper div img {
    position: relative;
  }

  div.curso .curso_wraper .descripcion {
    flex: 5;
  }

  div.curso .curso_wraper .descripcion p {
    color: #5C5C5C;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 0 20px 0 0;
  }

  div.curso .curso_wraper .boton {
    flex: 1;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    
}

/* Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {  
  .home .logo {
    margin: 15px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 20px;
    color: #000;
    padding: 15px 0;
    font-weight: bold;
    margin: 0;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61BB47;
    padding: 15px 0;
    margin: 0;
  }

  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 15px auto 45px auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  footer {
    width: 100%;
    height: 88px;
    background: #61BB47;
  }

  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 88px;
    text-align: left;
    padding: 0 0 0 134px;
    margin: 0;
  }

  footer img {
    margin: 10px 128px 0 0;
  }

  footer .logof {
      width: 63px;
      height: 63px;
      background-image: url('@/assets/images/logo_footer_big.png');
      background-position: center center;
      background-repeat: no-repeat;
      margin: 10px 128px 0 0;
      padding: 0;
      float: right;
    }
    
  .formulario {
    width: 360px;
    margin: 45px auto;
    background: #EAE7E4;
    border-radius: 6px;
    padding: 30px;
  }

  .player-wrapper {
    height: 524px;
    padding: 0 0 0 15px;
    margin: 15px 0;
  }

  .titulo_curso {
    text-align: left; 
    font-size:20px; 
    font-weight:bold; 
    color:#003366; 
    padding: 30px 15px 0 15px;
  }

  .titulo_curso_2 {
    text-align: left;
    font-size:20px; 
    font-weight:bold; 
    color:#003366;
    padding: 15px 15px 0 15px;
  }

  .titulo_instrucciones {
    text-align: left;
    font-size:16px; 
    font-weight:bold; 
    color:#5C5C5C; 
    padding: 15px 15px 20px 15px;
  }

  p.duracion {
    text-align: left;
    font-size:16px; 
    color:#5C5C5C;
    padding: 0 15px;
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

  div.header {
    background: #0093D0;
    height: 97px;
    width: 100%;
    margin: 14px 0;
    padding: 14px;
  }

  ul.app-instructions li p{
    font-size:16px; 
    color:#5C5C5C;
    padding: 0 0 15px 0;
  }

  div.curso {
    width: 100%;
    max-width: 100%;
    padding: 18px;
    background: rgba(248, 236, 236, 0.20);
    margin: 0 0 15px 0;
    text-align: left;
  }

  div.curso .curso_titulo {
    width: 100%;
  }

  div.curso .curso_titulo p {
    width: 100%;
    color: #036;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  div.curso .curso_titulo p span {
    color: #036;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  div.curso .curso_wraper {
    width: 100%;
    display: flex;
    flex-flow: row;
    margin: 15px 0;
  }

  div.curso .curso_wraper .imagen {
    flex: 1;
  }

  div.curso .curso_wraper .imagen .imagen_wraper {
    position: relative;
  }

  div.curso .curso_wraper .imagen .imagen_wraper div {
    position: absolute;
    top: 19px;
    left: 44px;
    z-index: 10;
  }

  div.curso .curso_wraper .imagen .imagen_wraper img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  div.curso .curso_wraper .imagen .imagen_wraper div img {
    position: relative;
  }

  div.curso .curso_wraper .descripcion {
    flex: 5;
  }

  div.curso .curso_wraper .descripcion p {
    color: #5C5C5C;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 0 20px 0 0;
  }

  div.curso .curso_wraper .boton {
    flex: 1;
  }
}
</style>

<script>
// @ is an alias to /src

import Loading from 'vue3-loading-overlay';

import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

import ElearningDataService from '../services/ElearningDataService';

export default {
  name: 'ModulosView',
  components: {
    Loading
  },

  mounted(){

    this.uid = localStorage.uid;

    let data = new FormData;

    data.append('uid', localStorage.uid);

    this.isLoading = true;

    ElearningDataService.userstatus(data).then(response => {
      this.isLoading = false;
      var terminado = response.data.userinfo.terminado;
      
      if(terminado == '1'){
        this.$router.push('/fin');
      }else{
        this.$router.push('/modulos');
      }
            
    }).catch(e => {
      console.log(e);
    });

    ElearningDataService.cursos(data).then(response => {
            
      let error = response.data.error;

      if(error){
        console.log("Error al obtener los cursos");
      }else{
        this.estadoCursos = response.data.cursos;

        for(var i=0;i<=10;i++){

          this.cursos[i].terminado = response.data.cursos[i].terminado;
          this.cursos[i].disponible = response.data.cursos[i].disponible;
        }

        console.log(response);
      }

      this.isLoading = false;
                        
    }).catch(e => {
      console.log(e);
    });
  },
  data: () => ({
    isLoading: false,
    estadoCursos: null,
    uid: 0,
    cursos: [
      { id: 0, titulo: "", imagen: '', descripcion: "", terminado: true, disponible: false},
      { id: 1, titulo: "Los micronutrimentos en el periodo periconcepcional", imagen: require("@/assets/images/cursos/ThumbnailsMOD1.jpg"), descripcion: "Se expondrá la importancia de los micronutrimentos en la etapa reproductiva, tanto en mujeres como en hombres, ya que su deficiencia se relaciona con infertilidad, además de enfermedades fetales y maternas, mientras que su suplementación reduce la incidencia de óbito fetal, bajo peso al nacer y parto pretérmino.", terminado: true, disponible: false},
      { id: 2, titulo: "La importancia de la nutrición durante los primeros 1000 días de vida", imagen: require("@/assets/images/cursos/ThumbnailsMOD2.jpg"), descripcion: "Aquí encontrará cómo las intervenciones nutricionales micro y macronutrimentales desde el primer día del embarazo hasta los dos años de edad pueden afectar de modo permanente el desarrollo biológico y metabólico. Recordará el concepto de programación fetal y la importancia de la epigenética en la nutrición.", terminado: false, disponible: false},
      { id: 3, titulo: "Influencia de los suplementos con micronutrimentos en los resultados del embarazo", imagen: require("@/assets/images/cursos/ThumbnailsMOD3.jpg"), descripcion: "Se explica el papel de los micronutrimentos y la importancia de su ingesta adecuada durante el embarazo. Se aborda su fisiología en la placenta y el feto, con un énfasis en las pautas de suplementación y estrategias para disminuir el riesgo de patologías relacionadas con su deficiencia. ", terminado: false, disponible: true},
      { id: 4, titulo: "Influencia de los micronutrimentos en la programación fetal", imagen: require("@/assets/images/cursos/ThumbnailsMOD4.jpg"), descripcion: "Se plantea la definición e impacto de la programación fetal sobre la vida posnatal, junto con sus factores determinantes, como la sobrealimentación y la desnutrición. Se destacan las consecuencias orgánicas de la deficiencia de los principales micronutrimentos durante el embarazo para la infancia y la adultez.", terminado: false, disponible: true},
      { id: 5, titulo: "Prevención de la preeclampsia a través de los micronutrimentos", imagen: require("@/assets/images/cursos/ThumbnailsMOD5.jpg"), descripcion: "Se abordará la etiología y mecanismos fisiopatológicos propuestos para la preeclampsia, entre los que destaca el estrés oxidativo materno. Se expondrá la influencia que tiene la dieta, en especial la deficiencia y suplementación de micronutrimentos, sobre el desarrollo de las enfermedades hipertensivas del embarazo.", terminado: false, disponible: true},
      { id: 6, titulo: "Diabetes gestacional y su relación con micronutrimentos", imagen: require("@/assets/images/cursos/ThumbnailsMOD6.jpg"), descripcion: "Dada la elevada prevalencia de diabetes gestacional en México, así como los desenlaces fetales y maternos adversos que conlleva, este módulo expondrá el rol que juega la leptina y el estrés oxidativo en esta patología, así como aquel que tiene la suplementación de micronutrimentos en la prevención de la misma.", terminado: false, disponible: true},
      { id: 7, titulo: "Suplementación con micronutrimentos en la prevención del parto pretérmino", imagen: require("@/assets/images/cursos/ThumbnailsMOD7.jpg"), descripcion: "Al ser un problema de salud pública, se analizará la fisiopatología y los factores de riesgo del parto pretérmino, entre los cuales destaca la nutrición, en particular las implicaciones del índice de masa corporal y la deficiencia de micronutrimentos, y se presentarán intervenciones nutricionales para prevenirlo.", terminado: false, disponible: true},
      { id: 8, titulo: "Malformaciones fetales y micronutrimentos", imagen: require("@/assets/images/cursos/ThumbnailsMOD8.jpg"), descripcion: "Dada la elevada morbimortalidad por malformaciones congénitas en México, por ejemplo, defectos del tubo neural y hendiduras orofaciales, es necesario implementar estrategias de prevención primaria, entre las que sobresalen las intervenciones nutricionales con manejo ponderal y suplementación de micronutrimentos. ", terminado: false, disponible: true},
      { id: 9, titulo: "Alteración en el crecimiento fetal y su relación con micronutrimentos", imagen: require("@/assets/images/cursos/ThumbnailsMOD9.jpg"), descripcion: "Se demostrará el papel del estado nutricional materno y de la placenta como mediadores en la restricción del crecimiento intrauterino, así como el vínculo entre deficiencias de micronutrimentos y neurotrofinas en el desarrollo placentario, que pueden dar paso a complicaciones del embarazo y alteraciones del crecimiento.", terminado: false, disponible: true},
      { id: 10, titulo: "Pérdidas gestacionales asociadas a los diferentes micronutrimentos", imagen: require("@/assets/images/cursos/ThumbnailsMOD10.jpg"), descripcion: "Los micronutrimentos modulan el metabolismo fetal y materno, participan en la reducción del estrés oxidativo e inflamación; su deficiencia puede conducir a pérdida gestacional. Este módulo presenta estrategias de suplementación para reducir el riesgo de aborto espontáneo y óbito fetal.", terminado: false, disponible: true},
    ]
  }),
  methods: {
     
    
  }
}



</script>
