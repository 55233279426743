import http from "../http-common";

class ElearningDataService {

    prueba(data) {
        return http.post("/prueba", data);
    }

    ingresar(data) {
      return http.post("/usuario/ingresar", data);
    }

    registrar(data) {
      return http.post("/usuario/registrar", data);
    }

    recuperar(data) {
      return http.post("/usuario/recuperar", data);
    }
    reintentar(data) {
      return http.post("/usuario/reiniciarProgreso", data);
    }

    userInfo(data) {
      return http.post("/usuario/userinfo", data);
    }

    userstatus(data) {
      return http.post("/usuario/userstatus", data);
    }

    cursos(data) {
      return http.post("/usuario/cursos", data);
    }

    verificarModuloTerminado(data) {
      return http.post("/usuario/verificarModuloTerminado", data);
    }

    calcularPuntaje(data) {
      return http.post("/usuario/calcularPuntaje", data);
    }

    obtenerDiploma(data) {
      return http.post("/usuario/obtenerDiploma", data, {responseType: 'blob'});
    }

    descargarDiploma(data) {
      return http.post("/usuario/descargarDiploma", data, {responseType: 'blob'});
    }

    reiniciarProgreso(data) {
      return http.post("/usuario/reiniciarProgreso", data);
    }

    verificarTerminado(data) {
      return http.post("/usuario/verificarTerminado", data);
    }

    perfil(data) {
      return http.post("/usuario/perfil", data);
    }

    perfilcompleto(data) {
      return http.post("/usuario/perfilcompleto", data);
    }

    actulizarPerfil(data) {
      return http.post("/usuario/actulizarPerfil", data);
    }
}

export default new ElearningDataService();