import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'


import useValidate from '@vuelidate/core'

let app = createApp(App);

app.use(router);
app.use(Vue3VideoPlayer);
app.use(VueSweetalert2);
app.use(useValidate);

app.mount('#app');