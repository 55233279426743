<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="logo-wrapper">
            <img src="@/assets/images/logo-micronutrientes-horizontal.png">
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="menu">
            <router-link to="/modulos" class="btn-inicio"><img src="@/assets/images/icon_home.png"></router-link>
            <router-link to="/perfil" class="btn-profile"><img src="@/assets/images/icon_profile.png"></router-link>
            <router-link to="/logout" class="btn-logout"><img src="@/assets/images/icon_sesion.png"></router-link>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="linea"></div>
        </div>
      </div>

    

      <!-- /animaciones//index.html -->
      <div class="row">
        <div class="col-lg-12" style="">
          <div class="wrapper_fin">
            <p class="titulo">Fin del curso</p>
            <p class="descripcion">Dé clic en el botón para conocer su resultado</p>
            <button @click="finalizar" class="btn-iniciar">Ver resultado</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <div style="">
            <img src="@/assets/images/footer.png" style="width: 100%;">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <footer>
            <div class="row">
              <div class="col-lg-6 col-8" style="">
                <p>Copyright &copy; Bayer AG </p>
              </div>
              <div class="col-lg-6 col-4" style=" text-align: right;">
                <div class="logof"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>  
  </div>

<loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
</template>

<style scoped>

  .wrapper_fin {
    min-height: 200px; 
    width: 100%;
    background-image: url('@/assets/images/fin_curso_telefono.png');
    background-position: center top;
    background-repeat: no-repeat;
    margin: 30px auto;
    background-size: contain;
  }

  .wrapper_fin p.titulo {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.49%;
    padding: 100px 0 0 0;
    margin: 0;
  }

  .wrapper_fin p.descripcion {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 128.49%;
    width: 210px;
    margin: 5px auto;
  }

  .btn-iniciar {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 30px auto 90px auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  div.menu {
    text-align: center;
    width: 100%;
    margin: 25px 0 0 0;
    display: flex;
    flex-flow: row;
  }

  div.menu a {
    height: 60px;
    line-height: 60px;
    flex: 1;
    text-align: center;
    display: block;
    width: 33%;
    flex-grow: 1;
  }

  div.menu a.btn-profile {
    margin: 0;
  }

  div.menu a.btn-logout {
    
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: center;
    padding: 15px ;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

  div.linea {
    background: #C5C3C3;
    height: 2px;
    width: 100%;
    margin: 0;
  }

  .home .logo {
    margin: 30px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 22px;
    color: #10384f;
    padding: 30px 0;
    font-weight: bold;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61bb47;
    padding: 15px 0;
  }

  .home .btn-start {
    border: 0;
    background-color: #fff;
    margin: 15px auto 0 auto;
  }

  footer {
    width: 100%;
    height: 58px;
    background: #61BB47;
  }

  
  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    padding: 0 0 0 15px;
    margin: 0;
  }

  footer img {
    margin: 7px 25px 0 0;
  }


  footer .logof {
    width: 42px;
    height: 43px;
    background-image: url('@/assets/images/logo_footer_small.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 7px 25px 0 0;
    padding: 0;
    float: right;
  }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
  
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  .formulario {
    width: 360px;
    margin: 45px auto;
    background: #EAE7E4;
    border-radius: 6px;
    padding: 30px;
  }

  .player-wrapper {
    height: 440px;
    padding: 15px;
  }

  ul.app-instructions li p{
    font-size:16px; 
    color:#5C5C5C;
    padding: 0 0 15px 0;
  }

  div.menu {
    text-align: right;
    width: 100%;
    margin: 15px 0;
    display: block;
  }

  div.menu a {
    height: 60px;
    display: inline-block;
    line-height: 60px;
    width: auto;
  }

  div.menu a.btn-profile {
    margin: 0 60px;
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 0;
  }

  .wrapper_alerta .texto p.titulo {
    color: #FF5050;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.49%;
    border-bottom: 2px solid #FF5050;
    display: inline-block;
    padding: 0 0 10px 0;
  }

  .wrapper_alerta .texto p.descripcion {
    color: #5C5C5C;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .wrapper_alerta .icono {
    margin: 40px 17px 0 0;
  }

  
  .wrapper-siguiente {
    width: 100%;
    height: 88px;
    background-color: #EAE7E4;
    margin: 0 0 30px 0;
    text-align: left;
    padding: 0;
  }

  .wrapper-siguiente img {
    line-height: 88px;
    display: inline-block;
    margin: 21px;
  }

  .wrapper-siguiente p {
    display: inline-block;
    color: #747474;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .wrapper_fin {
    min-height: 430px; 
    width: 100%;
    background-image: url('@/assets/images/fin_curso_tablet.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 30px auto;
  }

  .wrapper_fin p.titulo {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.49%;
    padding: 280px 0 0 0;
    margin: 0;
  }

  .wrapper_fin p.descripcion {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 128.49%;
  }

  .btn-iniciar {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 30px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    
}

/* Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {  
  .home .logo {
    margin: 15px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 20px;
    color: #000;
    padding: 15px 0;
    font-weight: bold;
    margin: 0;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61BB47;
    padding: 15px 0;
    margin: 0;
  }

  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 15px auto 45px auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  footer {
    width: 100%;
    height: 88px;
    background: #61BB47;
  }

  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 88px;
    text-align: left;
    padding: 0 0 0 134px;
    margin: 0;
  }

  footer img {
    margin: 10px 128px 0 0;
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

  .wrapper_fin {
    min-height: 430px; 
    width: 730px;
    background-image: url('@/assets/images/fin.png');
    background-position: center top;
    background-repeat: no-repeat;
    margin: 30px auto;
  }

  .wrapper_fin p.titulo {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.49%;
    padding: 160px 0 0 0;
    margin: 0;
  }

  .wrapper_fin p.descripcion {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 128.49%;
    width: 100%;
  }

  .btn-iniciar {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 100px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  footer .logof {
    width: 63px;
    height: 63px;
    background-image: url('@/assets/images/logo_footer_big.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 10px 128px 0 0;
    padding: 0;
    float: right;
  }
}
</style>

<script>
// @ is an alias to /src

import ElearningDataService from '../services/ElearningDataService';
import Loading from 'vue3-loading-overlay';

import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {

  name: 'FinView',
  components: { 
    Loading   
  },

  data: () => ({
    isLoading: false,
    uid: 0,
    aprobado: false
  }),
  computed: {
    
  },
  methods: {
    finalizar(){
      if(this.aprobado){
        this.$router.push('/aprobado/' + this.uid)
      }else{
        this.$router.push('/noaprobado/' + this.uid)
      }
    }
 
  },
  mounted(){

    this.uid = localStorage.uid;

    this.isLoading = true;

    let data = new FormData;
    data.append('uid', this.uid);

    ElearningDataService.calcularPuntaje(data).then(response => {
          this.isLoading = false;
          let aprobacion = response.data.aprobacion;
          
          console.log("Aprobación:" + aprobacion);

          if(aprobacion >= 80){
            //this.$router.push('/aprobado/' + this.$route.params.uid)
            this.aprobado = true;
          }else{
            //this.$router.push('/noaprobado/' + this.$route.params.uid)
            this.aprobado = false;
          }

        });
  },
  created() {
    
  }
}



</script>
