import axios from "axios";

export default axios.create({
  baseURL: "https://epdigital.cl/bayer2023/api/",
  headers: {
    "Content-type": 'application/x-www-form-urlencoded'
  }
});

//https://epdigital.cl/bayer2023/api/
//http://localhost/bayer_elearning/