<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div style="height: 40px; background-color: #fff;"></div>
        </div>
      </div>

      <div class="row">
       
        <div class="col-lg-12" style="background-color: #fff;">
          <div style="text-align: center;">
            <img src="@/assets/images/logo-micronutrientes-horizontal.png" class="logo">
           
            <div class="linea"></div>

            <div class="formulario">
              <img src="@/assets/images/registro_error_icon.png">
              <p class="formulario-titulo">Hubo un error en el registro</p>
              <p class="formulario-descripcion">Datos del registro fallido, por favor ingrese sus datos nuevamente</p>
              
              <router-link to="/registro" class="btn-login" >Volver a intentar</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <div style="">
            <img src="@/assets/images/footer.png" style="width: 100%;">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <footer>
            <div class="row">
              <div class="col-lg-6 col-8" style="">
                <p>Copyright &copy; Bayer AG </p>
              </div>
              <div class="col-lg-6 col-4" style=" text-align: right;">
                <div class="logof"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>

    </div>  
  </div>
</template>

<style scoped>
  .home .logo {
    margin: 15px auto 0 auto;
  }

  .formulario {
    width: 100%;
    margin: 0px auto 45px auto;
    background: rgba(248, 236, 236, 0.20);
    border-radius: 6px;
    padding: 80px 0;
    text-align: center;
  }

  .formulario p.formulario-titulo {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.49%;
    padding: 15px 0 0 0 ; 
    margin: 0;
   }

   .formulario p.formulario-descripcion {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 128.49%;
    padding: 0 ; 
   }

   div.linea {
    background: #C5C3C3;
    height: 2px;
    width: 100%;
    margin: 15px 0;
   }
  .formulario input[type='email'], .formulario input[type='password'], .formulario input[type='text'] {
    width: 300px;
    height: 48px;
    border-radius: 5px;
    background: #F4F4F4;
    border: 2px solid #F4F4F4;
  }

  .formulario input[type='email'] {
    background-image: url('@/assets/images/icon_email.svg');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

 

  .formulario input.error, .formulario select.error, .formulario .recaptcha_wrapper.error {
    border: 2px solid #F41313;
  }
  


  .formulario input.error::placeholder {
    color: #F41313;
    font-style: italic;
  }

  .formulario input.success, .formulario select.success, .formulario .recaptcha_wrapper.success {
    border: 2px solid #61BB47;
  }

  .formulario input.success::placeholder {
    color: #61BB47;
  }

  p.descripcion {
    color: #5A5857;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }

  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 45px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #0093D0;
  }

  .btn-login:hover {
    border: 2px solid #0093D0;
    background: #FFF;
    color: #0093D0;
  }
 
  p.recuperar {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 35px 0 0 0;
  }

  p.recuperar a {
    color: #003AE6;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
  }

  footer {
    width: 100%;
    height: 58px;
    background: #61BB47;
  }

  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    padding: 0 0 0 15px;
    margin: 0;
  }

  footer img {
    margin: 7px 25px 0 0;
  }

  footer .logof {
    width: 42px;
    height: 43px;
    background-image: url('@/assets/images/logo_footer_small.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 7px 25px 0 0;
    padding: 0;
    float: right;
  }
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
  
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  

  div.menu {
    text-align: right;
    width: 100%;
    margin: 15px 0;
    display: block;
  }

  div.menu a {
    height: 60px;
    display: inline-block;
    line-height: 60px;
    width: auto;
  }

  div.menu a.btn-profile {
    margin: 0 60px;
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 0;
  }

  
  .wrapper_puntaje {
  width: 640px;
  height: 193px;
  border-radius: 6px;
  border: 1px solid #0093D0;
  background: #FFF;
  margin: 15px auto 40px auto;
}

.wrapper_puntaje .header {
  width: 608px;
  height: 46px;
  border-radius: 6px;
  background: #E2EAFF;
  margin: 15px auto;
  padding: 0;
}

.wrapper_puntaje .header .puntaje {
  color: #61BB47;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
}

.wrapper_puntaje .header .puntaje span {
  color: #747474;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.wrapper_puntaje .descripcion {
  color: #5C5C5C;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin: 20px 0 0 0;
}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    
}

/* Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {  
  .home .logo {
    margin: 15px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 20px;
    color: #000;
    padding: 15px 0;
    font-weight: bold;
    margin: 0;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61BB47;
    padding: 15px 0;
    margin: 0;
  }

  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 15px auto 45px auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  footer {
    width: 100%;
    height: 88px;
    background: #61BB47;
  }

  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 88px;
    text-align: left;
    padding: 0 0 0 134px;
    margin: 0;
  }

  footer img {
    margin: 10px 128px 0 0;
  }

  footer .logof {
      width: 63px;
      height: 63px;
      background-image: url('@/assets/images/logo_footer_big.png');
      background-position: center center;
      background-repeat: no-repeat;
      margin: 10px 128px 0 0;
      padding: 0;
      float: right;
    }
    
  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

  div.header {
    background: #0093D0;
    height: 97px;
    width: 100%;
    margin: 14px 0;
    padding: 14px;
  }


  p.descripcion {
    color: #0093D0;
    text-align: center;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.49%;
    margin: 0;
    padding: 0;
  }

  .wrapper_puntaje {
  width: 640px;
  height: 193px;
  border-radius: 6px;
  border: 1px solid #0093D0;
  background: #FFF;
  margin: 15px auto 40px auto;
}

.wrapper_puntaje .header {
  width: 608px;
  height: 46px;
  border-radius: 6px;
  background: #E2EAFF;
  margin: 15px auto;
  padding: 0;
}

.wrapper_puntaje .header .puntaje {
  color: #61BB47;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
}

.wrapper_puntaje .header .puntaje span {
  color: #747474;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.wrapper_puntaje .descripcion {
  color: #5C5C5C;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin: 20px 0 0 0;
}
}  
</style>

<script>

import useValidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  name: 'RegistroRespuestaView',
  components: {
    
  },
  methods: {
    
    submitForm() {
      this.v$.$validate() // checks all inputs
      this.validado = true;
      if (!this.v$.$error) {
        //alert('Form successfully submitted.')
      } else {
        //alert('Form failed validation')
      }
    },
  },
  validations() {
    return {
      email: { required, email },
    }
  },
  data: () => ({
    v$: useValidate(),
    validado: false,
    email: '',
  })
}
</script>
