<template>

dfsdfsdfdsf
  <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
</template>

<style scoped>

</style>

<script>
// @ is an alias to /src

import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
  name: 'LogoutView',
  components: {
    Loading
  },
  mounted(){

    localStorage.removeItem('loggedin');
    localStorage.removeItem('uid');
    localStorage.removeItem('nombre');
    localStorage.removeItem('apellido_paterno');
    localStorage.removeItem('apellido_materno');
    localStorage.removeItem('email');
    localStorage.removeItem('estado');
    localStorage.removeItem('cedula');

    this.$router.push('/');
    
  },
  data: () => ({
    regs: [],
    isLoading: false
  }),
  methods: {
     
  }
}

</script>
