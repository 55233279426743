import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

import LoginView from '../views/LoginView.vue';
import LoginRespuestaView from '../views/LoginRespuestaView';
import LogoutView from '../views/LogoutView.vue';

import InicioView from '../views/InicioView.vue';
import ModulosView from '../views/ModulosView';
import ModuloView from '../views/ModuloView';
import TerminadoView from '../views/TerminadoView';
import AprobadoView from '../views/AprobadoView';
import NoaprobadoView from '../views/NoaprobadoView';

import RegistroView from '../views/RegistroView';
import RegistroRespuestaView from '../views/RegistroRespuestaView';

import RecuperarView from '../views/RecuperarView';
import RecuperarRespuestaView from '../views/RecuperarRespuestaView';

import PerfilView from '../views/PerfilView';
import EditarperfilView from '../views/EditarperfilView';

import FinView from '../views/FinView';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/login_respuesta',
    name: 'login_respuesta',
    component: LoginRespuestaView
  },
  {
    path: '/registro',
    name: 'registro',
    component: RegistroView
  },
  {
    path: '/registro_respuesta',
    name: 'registro_respuesta',
    component: RegistroRespuestaView
  },
  {
    path: '/recuperar',
    name: 'recuperar',
    component: RecuperarView
  },
  {
    path: '/recuperar_respuesta',
    name: 'recuperar_respuesta',
    component: RecuperarRespuestaView
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: PerfilView
  },
  {
    path: '/editar_perfil',
    name: 'editar_perfil',
    component: EditarperfilView
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: InicioView
  },
  {
    path: '/modulos',
    name: 'modulos',
    component: ModulosView
  },
  {
    path: '/modulo/:uid/:nModulo',
    name: 'modulo',
    component: ModuloView
  },
  {
    path: '/terminado/:uid',
    name: 'terminado',
    component: TerminadoView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/aprobado/:uid',
    name: 'aprobado',
    component: AprobadoView
  },
  {
    path: '/noaprobado/:uid',
    name: 'noaprobado',
    component: NoaprobadoView
  },
  {
    path: '/fin',
    name: 'fin',
    component: FinView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
