<template>
  <div class="home">
    <div class="container">
      <div class="row d-xl-flex d-none">
        <div class="col-lg-12">
          <div style="height: 40px; background-color: #fff;"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-7 d-xl-block d-md-block d-none">
          <div style="height: 535px;">
            <Carousel :autoplay="2000" :wrapAround="true">
              <Slide v-for="slide in slides" :key="slide">
                <div class="carousel__item"  v-bind:style="{ backgroundImage: 'url(' + slide.url + ')' }"></div>
              </Slide>

              <template #addons>
                <Navigation />
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
        <div class="col-lg-5" style="background-color: #fff;">
          <div style="text-align: center;">
            <img src="@/assets/images/login_logo.png" class="logo">
           
            <div class="formulario">
              <p class="formulario-titulo">Ingrese sus datos:</p>
              <div class="mb-3">
                <input type="email" class="form-control" :class="{ error: v$.username.$errors.length, success: v$.username.$errors.length == 0 && validado }"  id="username" aria-describedby="emailHelp" placeholder="Correo electrónico" v-model="username">
              </div>
              <div class="mb-3">
                <div class="password-wrapper">
                  <input :type="passwordFieldType" class="form-control" :class="{ error: v$.password.$errors.length, success: v$.password.$errors.length == 0 && validado }" id="password" aria-describedby="emailHelp" placeholder="Contraseña" v-model="password">
                  <button class="password_visibility_changer" @click="cambiarModoPassword" :class="{ on: modoPassword, off: !modoPassword }"></button>
                </div>
              </div>
              <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="recordar" v-model="recordar">
                <label class="form-check-label" for="exampleCheck1">Recordar contraseña</label>
              </div>
            </div>
            
            <button class="btn-login" @click="submitForm">Ingresar</button>
            <p class="recuperar">¿Ha olvidado su contraseña? <router-link to="/recuperar" class="btn-recuperar">Recuperar aquí </router-link></p>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <div style="">
            <img src="@/assets/images/footer.png" style="width: 100%;">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <footer>
            <div class="row">
              <div class="col-lg-6 col-8" style="">
                <p>Copyright &copy; Bayer AG </p>
              </div>
              <div class="col-lg-6 col-4" style=" text-align: right;">
                <div class="logof"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>

    </div>  
  </div>
</template>

<style scoped>
  .home .logo {
    margin: 15px auto 0 auto;
  }

  .formulario {
    width: 300px;
    margin: 45px auto 0 auto;
  }

  .formulario p.formulario-titulo {
    color: #5C5C5C;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }

  .formulario input[type='email'], .formulario input[type='password'], .formulario input[type='text'] {
    width: 300px;
    height: 48px;
    border-radius: 5px;
    background: #F4F4F4;
    border: 2px solid #fff;
  }

  .formulario input[type='email'] {
    background-image: url('@/assets/images/icon_email.svg');
    background-position: 17px 17px;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario input[type='password'], .formulario input[type='text'] {
    background-image: url('@/assets/images/icon_key.svg');
    background-position: 17px 17px;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario .form-check {
    text-align: left;
  }

  .formulario .form-check label {
    color: #747474;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    vertical-align: text-top;
    padding: 0 0 0 15px;
  }

  .formulario .password-wrapper {
    width: 100%;
    position: relative;
  }

  .formulario .password-wrapper .password_visibility_changer {
    position: absolute;
    width: 48px;
    height: 48px;
    background-color: transparent;
    top: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center center;
    border: 0;
  }

  .formulario .password-wrapper .password_visibility_changer.on {
    background-image: url('@/assets/images/icon_eye_open.png');
  }

  .formulario .password-wrapper .password_visibility_changer.off {
    background-image: url('@/assets/images/icon_eye_close.png');
  }

  .formulario input[type='checkbox'] {
    width: 28px;
    height: 28px;
  }

  .formulario input.error {
    border: 2px solid #F41313;
  }
  

  .formulario input.error::placeholder {
    color: #F41313;
    font-style: italic;
  }

  .formulario input.success {
    border: 2px solid #61BB47;
  }

  .formulario input.success::placeholder {
    color: #61BB47;
  }

  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 45px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  .btn-login:hover {
    border: 2px solid #0093D0;
    background: #FFF;
    color: #0093D0;
  }
 
  p.recuperar {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 35px 0 0 0;
  }

  p.recuperar a {
    color: #003AE6;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
  }

  footer {
    width: 100%;
    height: 58px;
    background: #61BB47;
  }

  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    padding: 0 0 0 15px;
    margin: 0;
  }

  footer img {
    margin: 7px 25px 0 0;
  }

  footer .logof {
    width: 42px;
    height: 43px;
    background-image: url('@/assets/images/logo_footer_small.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 7px 25px 0 0;
    padding: 0;
    float: right;
  }
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {  
  
  }

  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (min-width: 768px) {  

  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {  
      
  }

  /* Extra extra large devices (extra large desktops, 1400px and up) */
  @media (min-width: 1400px) {  
    .home .logo {
      margin: 15px auto 0 auto;
    }

    .home h1.app-title {
      font-size: 20px;
      color: #000;
      padding: 15px 0;
      font-weight: bold;
      margin: 0;
    }

    .home h2.app-description {
      font-size: 18px;
      color: #61BB47;
      padding: 15px 0;
      margin: 0;
    }

    footer {
      width: 100%;
      height: 88px;
      background: #61BB47;
    }

    footer p {
      color: #FFF;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 88px;
      text-align: left;
      padding: 0 0 0 134px;
      margin: 0;
    }

    footer img {
      margin: 10px 128px 0 0;
    }

    footer .logof {
      width: 63px;
      height: 63px;
      background-image: url('@/assets/images/logo_footer_big.png');
      background-position: center center;
      background-repeat: no-repeat;
      margin: 10px 128px 0 0;
      padding: 0;
      float: right;
    }

  }
</style>

<style>
  .carousel__item {
    height: 535px;
    width: 100%;
    background-position: center center;
    background-size: cover;
  }

  .carousel__pagination {
    display: none;
  }

  .carousel__next {
    transform: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 100%;
    right: 10px;
  }

  .carousel__prev {
    transform: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 100%;
    left: 10px;
  }

  .form-check-input:checked[type=checkbox] {
    background-image: url('@/assets/images/checkbox.png') !important;
  }

  .form-check-input:checked {
    background-color: #fff !important;
    border-color: #CDCDCD !important;
  }


</style>
<script>
// @ is an alias to /src
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

import useValidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import ElearningDataService from '../services/ElearningDataService';

export default {
  name: 'LoginView',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    cambiarModoPassword() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.modoPassword = !this.modoPassword;
    },
    submitForm() {
      this.v$.$validate() // checks all inputs
      this.validado = true;
      if (!this.v$.$error) {
        
        this.isLoading = true;

        console.log("Ingresando usuario."); 

        let data = new FormData;

        data.append('email', this.username);
        data.append('password', this.password);

        ElearningDataService.ingresar(data)
          .then(response => {
            
            let error = response.data.error;

            if(error){
              this.$router.push('/login_respuesta');
            }else{

              if(this.recordar){
                localStorage.loggedin = true;
              }

              let userdata = response.data.data;
              localStorage.nombre = userdata.nombre;
              localStorage.apellido_paterno = userdata.ap_paterno;
              localStorage.apellido_materno = userdata.ap_materno;
              localStorage.email = userdata.email;
              localStorage.cedula = userdata.ci;
              localStorage.estado = userdata.estado;
              localStorage.uid = userdata.id; 

              this.$router.push('/inicio');
            }

            this.isLoading = false;
                        
          })
          .catch(e => {
            console.log(e);
          });

      } else {
        //alert('Form failed validation')
      }
    },
  },
  validations() {
    return {
      username: { required, email },
      password: { required },
    }
  },
  data: () => ({
    v$: useValidate(),
    validado: false,
    modoPassword: false,
    passwordFieldType: 'password',
    username: '',
    password: '',
    recordar: false,
    slides: [
      {url: require('@/assets/images/slider1.jpg')},
      {url: require('@/assets/images/slider2.jpg')},
      {url: require('@/assets/images/slider3.jpg')},
      {url: require('@/assets/images/slider4.jpg')},
    ]
  })
}
</script>
