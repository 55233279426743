<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="logo-wrapper">
            <img src="@/assets/images/logo-micronutrientes-horizontal.png">
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="menu">
            <a class="btn-inicio" @click="confirmarSalir('home')"><img src="@/assets/images/icon_home.png"></a>
            <a class="btn-profile" @click="confirmarSalir('perfil')"><img src="@/assets/images/icon_profile.png"></a>
            <a class="btn-logout" @click="confirmarSalir('salir')"><img src="@/assets/images/icon_sesion.png"></a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="linea"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="header">
            <h1>Módulo {{this.$route.params.nModulo}}</h1>
            <h2>{{modulos[this.$route.params.nModulo].titulo}}</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div>
            <div class="wrapper_alerta" v-show="mostrarAlerta">
              <div class="icono">
                <img src="@/assets/images/alerta_70.png" class="d-xl-block d-md-none d-none">
                <img src="@/assets/images/alerta_65.png" class="d-xl-none d-md-block d-none">
                <img src="@/assets/images/alerta_50.png" class="d-xl-none d-md-none d-block">
              </div>
              <div class="texto">
                <p class="titulo">Nota importante</p>
                <p class="descripcion"><strong>Si decide cerrar su sesión en el módulo aún sin terminarlo,</strong> tenga en cuenta que perderá el progreso realizado hasta el momento. Si es así, <strong>cuando inicie sesión nuevamente deberá empezar el módulo desde el principio,</strong> así que lo mejor es completarlo en una sola sesión y evitar complicaciones.</p>
                <div class="confirmacion">
                  <p>¿Esta seguro de abandonar el módulo?</p>
                  <a href="javascript:;" @click="continuar" class="si">Si</a><a @click="cancelar" href="javascript:;" class="no">No</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /animaciones//index.html -->
      <div class="row">
        <div class="col-lg-12">
          <div class="iframe-wrapper">
            <iframe ref="contenido" :src="modulos[this.$route.params.nModulo].ruta + this.$route.params.uid" scrolling="no" style="width:100%; margin:0; padding:0; border:none; overflow:hidden;" :style="{ height: altoCalculado +'px' }"></iframe>
          </div>
        </div>
      </div>

      <!--<div class="row">
      </div>-->
      <div class="row" v-if="habilitarSiguiente">
        <div class="col-lg-12">
          <div class="wrapper-siguiente">
            <div class="row">
            <div class="col-lg-8 col-md-6 col-12">
              <img src="@/assets/images/Check.png">
              <p>{{ txtSiguiente }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <button @click="cargarSiguienteModulo" class="btn-iniciar">{{txtBoton}}</button>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <footer>
            <div class="row">
              <div class="col-lg-6 col-8" style="">
                <p>Copyright &copy; Bayer AG </p>
              </div>
              <div class="col-lg-6 col-4" style=" text-align: right;">
                <div class="logof"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>  
  </div>

  <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
</template>

<style scoped>

  .wrapper_alerta {
    width: 100%;
    padding: 10px 17px;
    display: flex;
    flex-flow: row;
    border: 2px solid #FF5050;
    background-color: #F3F0E8;
    margin: 0 auto;
  }

  .wrapper_alerta .icono {
    margin: 90px 17px 0 0;
  }

  .wrapper_alerta .texto {
    text-align: left;
  }

  .wrapper_alerta .texto p.titulo {
    color: #FF5050;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.49%;
    border-bottom: 2px solid #FF5050;
    display: inline-block;
    padding: 0 0 10px 0;
  }

  .wrapper_alerta .texto p.descripcion {
    color: #5C5C5C;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
  }

  .wrapper_alerta .confirmacion {
    width: 100%;
  }

  .wrapper_alerta .confirmacion p {
    color: #000;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: block;
    margin: 0 0 15px 0;
  }

  .wrapper_alerta .confirmacion a.si {
    display: inline-block;
    width: 80px;
    height: 41px;
    line-height: 41px;
    text-align: center;
    color: #fff;
    border-radius: 30px;
    background: #0093D0;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin: 0 15px 0 0;
    text-decoration: none;
  }

  .wrapper_alerta .confirmacion a.no {
    display: inline-block;
    width: 80px;
    height: 41px;
    line-height: 41px;
    text-align: center;
    color: #fff;
    border-radius: 30px;
    background: #747474;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
  }

  .wrapper-siguiente {
    width: 100%;
    height: auto;
    background-color: #EAE7E4;
    margin: 0 0 30px 0;
    text-align: center;
    padding: 15px 0;
  }

  .wrapper-siguiente img {
    line-height: 88px;
    display: inline-block;
    margin: 21px 21px 21px 0;
  }

  .wrapper-siguiente p {
    display: inline-block;
    color: #747474;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .btn-iniciar {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 0px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #EAE7E4;
  }

  div.menu {
    text-align: center;
    width: 100%;
    margin: 25px 0 0 0;
    display: flex;
    flex-flow: row;
  }

  div.menu a {
    height: 60px;
    line-height: 60px;
    flex: 1;
    text-align: center;
    display: block;
    width: 33%;
    flex-grow: 1;
  }

  div.menu a.btn-profile {
    margin: 0;
  }

  div.menu a.btn-logout {
    
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: center;
    padding: 15px ;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

  div.linea {
    background: #C5C3C3;
    height: 2px;
    width: 100%;
    margin: 0;
  }

  div.header {
    background: #0093D0;
    height: auto;
    width: 100%;
    margin: 14px 0;
    padding: 14px;
  }

  div.header h1 {
    color: #FFF;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
  }

  div.header h2 {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin: 0;
  }

  .home .logo {
    margin: 30px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 22px;
    color: #10384f;
    padding: 30px 0;
    font-weight: bold;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61bb47;
    padding: 15px 0;
  }

  .home .btn-start {
    border: 0;
    background-color: #fff;
    margin: 15px auto 0 auto;
  }


  footer {
    width: 100%;
    height: 58px;
    background: #61BB47;
  }

  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    padding: 0 0 0 15px;
    margin: 0;
  }

  footer img {
    margin: 7px 25px 0 0;
  }

  footer .logof {
    width: 42px;
    height: 43px;
    background-image: url('@/assets/images/logo_footer_small.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 7px 25px 0 0;
    padding: 0;
    float: right;
  }

  .iframe-wrapper {
    padding: 15px 0;
  }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
  
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  .formulario {
    width: 360px;
    margin: 45px auto;
    background: #EAE7E4;
    border-radius: 6px;
    padding: 30px;
  }

  .player-wrapper {
    height: 440px;
    padding: 15px;
  }

  ul.app-instructions li p{
    font-size:16px; 
    color:#5C5C5C;
    padding: 0 0 15px 0;
  }

  div.menu {
    text-align: right;
    width: 100%;
    margin: 15px 0;
    display: block;
  }

  div.menu a {
    height: 60px;
    display: inline-block;
    line-height: 60px;
    width: auto;
  }

  div.menu a.btn-profile {
    margin: 0 60px;
  }

  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 0;
  }

  .wrapper_alerta .texto p.titulo {
    color: #FF5050;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.49%;
    border-bottom: 2px solid #FF5050;
    display: inline-block;
    padding: 0 0 10px 0;
  }

  .wrapper_alerta .texto p.descripcion {
    color: #5C5C5C;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .wrapper_alerta .icono {
    margin: 40px 17px 0 0;
  }
  
  .wrapper_alerta .confirmacion p {
    display: inline-block;
    margin: 0 30px 0 0;
  }

  .wrapper-siguiente {
    width: 100%;
    height: 88px;
    background-color: #EAE7E4;
    margin: 0 0 30px 0;
    text-align: left;
    padding: 0;
  }

  .wrapper-siguiente img {
    line-height: 88px;
    display: inline-block;
    margin: 21px;
  }

  .wrapper-siguiente p {
    display: inline-block;
    color: #747474;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .btn-iniciar {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 20px 0 0 0;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #EAE7E4;
    float: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    
}

/* Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {  
  .home .logo {
    margin: 15px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 20px;
    color: #000;
    padding: 15px 0;
    font-weight: bold;
    margin: 0;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61BB47;
    padding: 15px 0;
    margin: 0;
  }

  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 15px 20px 45px 0;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
    float: right;
  }

  footer {
    width: 100%;
    height: 88px;
    background: #61BB47;
  }
  
  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 88px;
    text-align: left;
    padding: 0 0 0 134px;
    margin: 0;
  }

  footer img {
    margin: 10px 128px 0 0;
  }

  footer .logof {
      width: 63px;
      height: 63px;
      background-image: url('@/assets/images/logo_footer_big.png');
      background-position: center center;
      background-repeat: no-repeat;
      margin: 10px 128px 0 0;
      padding: 0;
      float: right;
    }
  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: left;
    padding: 0 ;
    margin: 15px 0;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

  div.header {
    background: #0093D0;
    height: 97px;
    width: 100%;
    margin: 14px 0;
    padding: 14px;
  }

  .wrapper_alerta {
    width: 100%;
    padding: 10px 17px;
    display: flex;
    flex-flow: row;
    border: 2px solid #FF5050;
    background-color: #F3F0E8;
    margin: 0 auto;
  }

  .wrapper_alerta .icono {
    margin: 30px 17px 0 0;
  }

  .wrapper_alerta .texto {
    text-align: left;
  }

  .wrapper_alerta .texto p.titulo {
    color: #FF5050;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.49%;
    border-bottom: 2px solid #FF5050;
    display: inline-block;
    padding: 0 0 10px 0;
  }

  .wrapper_alerta .texto p.descripcion {
    color: #5C5C5C;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
  }

  .wrapper_alerta .confirmacion p {
    display: inline-block;
    margin: 0 30px 0 0;
  }

  .wrapper-siguiente {
    width: 100%;
    height: 88px;
    background-color: #EAE7E4;
    margin: 0 0 30px 0;
    text-align: left;
    padding: 0;
  }

  .wrapper-siguiente img {
    line-height: 88px;
    display: inline-block;
    margin: 21px;
  }

  .wrapper-siguiente p {
    display: inline-block;
    color: #747474;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .btn-iniciar {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 20px 20px 0 0;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #EAE7E4;
    float: right;
  }
}
</style>

<script>
// @ is an alias to /src


import ElearningDataService from '../services/ElearningDataService';
import Loading from 'vue3-loading-overlay';

import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {

  name: 'ModuloView',
  components: { 
    Loading   
  },

  data: () => ({
    destino: '',
    mostrarAlerta: false,
    ci: '',
    habilitarSiguiente: false,
    siguienteModulo: 0,
    isLoading: false,
    txtBoton: '',
    txtSiguiente: '',
    height: '',
    width:'',
    altoCalculado: 0,
    modulos: [
      { ruta: '/animaciones2/Mod0/index.html?uid=', indice: 0, titulo: '' },
      { ruta: '/animaciones2/Mod1/index.html?uid=', indice: 1, titulo: 'Los micronutrimentos en el periodo periconcepcional' },
      { ruta: '/animaciones2/Mod2/index.html?uid=', indice: 2, titulo: 'La importancia de la nutrición durante los primeros 1000 días de vida' },
      { ruta: '/animaciones2/Mod3/index.html?uid=', indice: 3, titulo: 'Influencia de los suplementos con micronutrimentos en los resultados del embarazo' },
      { ruta: '/animaciones2/Mod4/index.html?uid=', indice: 4, titulo: 'Influencia de los micronutrimentos en la programación fetal' },
      { ruta: '/animaciones2/Mod5/index.html?uid=', indice: 5, titulo: 'Prevención de la preeclampsia a través de los micronutrimentos' },
      { ruta: '/animaciones2/Mod6/index.html?uid=', indice: 6, titulo: 'Diabetes gestacional y su relación con micronutrimentos' },
      { ruta: '/animaciones2/Mod7/index.html?uid=', indice: 7, titulo: 'Suplementación con micronutrimentos en la prevención de parto pretérmino' },
      { ruta: '/animaciones2/Mod8/index.html?uid=', indice: 8, titulo: 'Malformaciones fetales y micronutrimentos' },
      { ruta: '/animaciones2/Mod9/index.html?uid=', indice: 9, titulo: 'Alteración en el crecimiento fetal y su relación con micronutrimentos' },
      { ruta: '/animaciones2/Mod10/index.html?uid=', indice: 10, titulo: 'Pérdidas gestacionales asociadas a los diferentes micronutrimentos' },
    ]
  }),
  computed: {
    
  },
  methods: {

    confirmarSalir: function(destino){
      this.destino = destino;
      this.mostrarAlerta = true;
    },
    continuar: function(){
      if(this.destino == 'home'){
        this.$router.push('/modulos/')
      }else if(this.destino == 'perfil'){
        this.$router.push('/perfil/')
      }else if(this.destino == 'salir'){
        this.$router.push('/logout/')
      }
    },
    cancelar: function(){
      this.mostrarAlerta = false;
    },
    verificarModuloTerminado: function(){
       let data = new FormData;

        data.append('modulo', this.$route.params.nModulo);
        data.append('uid', this.$route.params.uid);
        
        var _this = this;

        let intervalo = setInterval(function(){
          ElearningDataService.verificarModuloTerminado(data).then(response => {
            //console.log(response.data);

            if(response.data.terminado == true){

              let a_idModulo = parseInt(_this.$route.params.nModulo)

              if(a_idModulo<10){
                let siguiente_modulo_n = a_idModulo +1;
                _this.txtBoton = 'Módulo ' + siguiente_modulo_n;
                _this.txtSiguiente = "Siguiente módulo";
              }else{
                _this.txtBoton = 'Finalizar';
                _this.txtSiguiente = "Fin del curso"
              }

              
              _this.habilitarSiguiente = true;
              clearInterval(intervalo);
              //console.log("SE CUMPLIÓ EL INTERVALO");
            }
          })
          .catch(e => {
            console.log(e);
          });
        }, 1000)
     },

     cargarSiguienteModulo: function(){

      let a_idModulo = parseInt(this.$route.params.nModulo)
      let a_uid = parseInt(this.$route.params.uid)

      this.habilitarSiguiente = false;
      //let a_idModulo = 10

      if(a_idModulo<10){
        this.siguienteModulo = a_idModulo +1;
        
        this.$router.push('/modulo/'+a_uid+'/' + this.siguienteModulo)
      }else{
        this.siguienteModulo = 99;
        this.isLoading = true;

        let data = new FormData;
        data.append('uid', this.$route.params.uid);

        this.$router.push('/fin')
        /*
        ElearningDataService.calcularPuntaje(data).then(response => {
          
          let aprobacion = response.data.aprobacion;

          if(aprobacion >= 80){
            this.$router.push('/aprobado/' + this.$route.params.uid)
          }else{
            this.$router.push('/noaprobado/' + this.$route.params.uid)
          }

        });
        */
      }

       
     }
 
  },
  mounted(){

    this.$nextTick(() => {
          const element = this.$refs.contenido
          const width = element.offsetWidth
          const height = element.offsetHeight
          this.height = height
          this.width = width

          //console.log("Ancho:" + this.width);
          //console.log("Alto:" + this.height);

          let factor = 1920 / 1080;

          this.altoCalculado = width/factor;

        });
    this.verificarModuloTerminado();
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        console.log(toParams);
        console.log(previousParams);

        if(toParams.nModulo != undefined){
          this.verificarModuloTerminado();
        }
        
      }
    )
  }
}



</script>
