<template>
  <div class="home">
    <div class="container">
      <div class="row d-xl-flex d-md-flex d-none">
        <div class="col-lg-12">
          <div style="height: 40px; background-color: #fff;"></div>
        </div>
      </div>

      <div class="row">
       
        <div class="col-lg-12" style="background-color: #fff;">
          <div style="text-align: center;">
            <img src="@/assets/images/logo-micronutrientes-horizontal.png" class="logo">
           
            <div class="formulario">
              <p class="formulario-titulo">Registrarse</p>
              <div class="mb-3">
                <input type="text" class="form-control input-nombre" :class="{ error: v$.nombre.$errors.length, success: v$.nombre.$errors.length == 0 && validado }"  id="nombre" aria-describedby="emailHelp" placeholder="Nombre" v-model="nombre">
              </div>

              <div class="mb-3">
                <input type="text" class="form-control input-apellido_paterno" :class="{ error: v$.apellido_paterno.$errors.length, success: v$.apellido_paterno.$errors.length == 0 && validado }"  id="apellido_paterno" aria-describedby="emailHelp" placeholder="Apellido paterno" v-model="apellido_paterno">
              </div>

              <div class="mb-3">
                <input type="text" class="form-control input-apellido_materno" :class="{ error: v$.apellido_materno.$errors.length, success: v$.apellido_materno.$errors.length == 0 && validado }"  id="apellido_materno" aria-describedby="emailHelp" placeholder="Apellido materno" v-model="apellido_materno">
              </div>

              <div class="mb-3">
                <input type="email" class="form-control input-email" :class="{ error: v$.email.$errors.length, success: v$.email.$errors.length == 0 && validado }"  id="email" aria-describedby="emailHelp" placeholder="Correo electrónico" v-model="email">
              </div>

              <div class="mb-3">
                <input type="text" class="form-control input-cedula" :class="{ error: v$.cedula.$errors.length, success: v$.cedula.$errors.length == 0 && validado }"  id="cedula" aria-describedby="emailHelp" placeholder="Cédula profesional" v-model="cedula">
              </div>

              <div class="mb-3">
                <select class="form-select input-estado" :class="{ error: v$.estado.$errors.length, success: v$.estado.$errors.length == 0 && validado }"  id="estado" aria-describedby="emailHelp" placeholder="Seleccione su estado" v-model="estado">
                  <option value="">Seleccione su estado</option>
                  <option value="Aguascalientes">Aguascalientes</option>
                  <option value="Baja California">Baja California</option>
                  <option value="Baja California Sur">Baja California Sur</option>
                  <option value="Campeche">Campeche</option>
                  <option value="Chiapas">Chiapas</option>
                  <option value="Chihuahua">Chihuahua</option>
                  <option value="Ciudad de México">Ciudad de México</option>
                  <option value="Coahuila">Coahuila</option>
                  <option value="Colima">Colima</option>
                  <option value="Durango">Durango</option>
                  <option value="Estado de México">Estado de México</option>
                  <option value="Guanajuato">Guanajuato</option>
                  <option value="Guerrero">Guerrero</option>
                  <option value="Hidalgo">Hidalgo</option>
                  <option value="Jalisco">Jalisco</option>
                  <option value="Michoacán">Michoacán</option>
                  <option value="Morelos">Morelos</option>
                  <option value="Nayarit">Nayarit</option>
                  <option value="Nuevo León">Nuevo León</option>
                  <option value="Oaxaca">Oaxaca</option>
                  <option value="Puebla">Puebla</option>
                  <option value="Querétaro">Querétaro</option>
                  <option value="Quintana Roo">Quintana Roo</option>
                  <option value="San Luis Potosí">San Luis Potosí</option>
                  <option value="Sinaloa">Sinaloa</option>
                  <option value="Sonora">Sonora</option>
                  <option value="Tabasco">Tabasco</option>
                  <option value="Tamaulipas">Tamaulipas</option>
                  <option value="Tlaxcala">Tlaxcala</option>
                  <option value="Veracruz">Veracruz</option>
                  <option value="Yucatán">Yucatán</option>
                  <option value="Zacatecas">Zacatecas</option>
                </select>
              </div>

              <div class="mb-3">
                <div class="password-wrapper">
                  <input :type="passwordFieldType" class="form-control input-password" :class="{ error: v$.password.$errors.length, success: v$.password.$errors.length == 0 && validado }" id="password" aria-describedby="emailHelp" placeholder="Contraseña" v-model="password">
                  <button class="password_visibility_changer" @click="cambiarModoPassword" :class="{ on: modoPassword, off: !modoPassword }"></button>
                </div>
              </div>

              <div class="mb-3">
                <div class="recaptcha_wrapper">
                  <vue-recaptcha v-show="showRecaptcha" sitekey="6Lc7WJcnAAAAABRYApc6GZAGDU4lAk2ZSACKjrrv" size="normal" theme="light" hl="es-419" :loading-timeout="loadingTimeout" @verify="recaptchaVerified" @expire="recaptchaExpired" @fail="recaptchaFailed" @error="recaptchaError" ref="vueRecaptcha"></vue-recaptcha>
                  <p v-if="!recaptchaValidado && validado" class="error_recaptcha">Verifique que no es un robot</p>
                </div>
              </div>
              <button class="btn-login" @click="submitForm">Crear cuenta</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <div style="">
            <img src="@/assets/images/footer.png" style="width: 100%;">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <footer>
            <div class="row">
              <div class="col-lg-6 col-8" style="">
                <p>Copyright &copy; Bayer AG </p>
              </div>
              <div class="col-lg-6 col-4" style=" text-align: right;">
                <div class="logof"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>

    </div>  
  </div>
  <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
</template>

<style scoped>
  .home .logo {
    margin: 15px auto 0 auto;
  }

  .formulario {
    width: 100%;
    margin: 25px auto;
    background: #EAE7E4;
    border-radius: 6px;
    padding: 30px;
  }

  .formulario p.formulario-titulo {
    color: #28287C;
    text-align: center;
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }

  .formulario input[type='email'], .formulario input[type='password'], .formulario input[type='text'] {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background: #F4F4F4;
    border: 2px solid #F4F4F4;
  }

  .formulario input[type='email'] {
    background-image: url('@/assets/images/icon_email.svg');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario input.input-password {
    background-image: url('@/assets/images/icon_key.svg');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario input.input-nombre {
    background-image: url('@/assets/images/icon_nombre.png');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario input.input-apellido_paterno, .formulario input.input-apellido_materno {
    background-image: url('@/assets/images/icon_apellidos.png');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario input.input-cedula {
    background-image: url('@/assets/images/icon_cedula.png');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

  .formulario select.input-estado {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background: #F4F4F4;
    border: 2px solid #F4F4F4;
    background-image: url('@/assets/images/icon_estado.png');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }


  .formulario select.form-select:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 100%;
    line-height: 38px;
    content: "∨";
    text-align: center;
    color: #714BB9;
    font-size: 24px;
    border-left: 1px solid #3C1C78;
    z-index: -1;
  } 

  .formulario .form-check {
    text-align: left;
  }

  .formulario .form-check label {
    color: #747474;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    vertical-align: text-top;
    padding: 0 0 0 15px;
  }

  .formulario .password-wrapper {
    width: 100%;
    position: relative;
  }

  .formulario .password-wrapper .password_visibility_changer {
    position: absolute;
    width: 48px;
    height: 48px;
    background-color: transparent;
    top: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center center;
    border: 0;
  }

  .formulario .password-wrapper .password_visibility_changer.on {
    background-image: url('@/assets/images/icon_eye_open.png');
  }

  .formulario .password-wrapper .password_visibility_changer.off {
    background-image: url('@/assets/images/icon_eye_close.png');
  }

  .formulario input[type='checkbox'] {
    width: 28px;
    height: 28px;
  }

  .formulario .recaptcha_wrapper {
    width: 100%;
    border-radius: 5px;
  }
  .formulario input.error, .formulario select.error, .formulario .recaptcha_wrapper.error {
    border: 2px solid #F41313;
  }
  
  .formulario p.error_recaptcha {
    color: #F41313;
    font-size: 12px;
    padding: 5px 0 0 0;
    text-align: center;
    font-style: italic;
  }

  .formulario input.error::placeholder {
    color: #F41313;
    font-style: italic;
  }

  .formulario input.success, .formulario select.success, .formulario .recaptcha_wrapper.success {
    border: 2px solid #61BB47;
  }

  .formulario input.success::placeholder {
    color: #61BB47;
  }

  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 45px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #0093D0;
  }

  .btn-login:hover {
    border: 2px solid #0093D0;
    background: #FFF;
    color: #0093D0;
  }
 
  p.recuperar {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 35px 0 0 0;
  }

  p.recuperar a {
    color: #003AE6;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
  }

  footer {
    width: 100%;
    height: 58px;
    background: #61BB47;
  }

  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    padding: 0 0 0 15px;
    margin: 0;
  }

  footer img {
    margin: 7px 25px 0 0;
  }

  footer .logof {
    width: 42px;
    height: 43px;
    background-image: url('@/assets/images/logo_footer_small.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 7px 25px 0 0;
    padding: 0;
    float: right;
  }
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {  
  
  }

  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (min-width: 768px) {  
    .formulario {
      width: 360px;
      margin: 45px auto;
      background: #EAE7E4;
      border-radius: 6px;
      padding: 30px;
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {  
      
  }

  /* Extra extra large devices (extra large desktops, 1400px and up) */
  @media (min-width: 1400px) {  
    .home .logo {
      margin: 15px auto 0 auto;
    }

    .home h1.app-title {
      font-size: 20px;
      color: #000;
      padding: 15px 0;
      font-weight: bold;
      margin: 0;
    }

    .home h2.app-description {
      font-size: 18px;
      color: #61BB47;
      padding: 15px 0;
      margin: 0;
    }

    footer {
      width: 100%;
      height: 88px;
      background: #61BB47;
    }

    footer p {
      color: #FFF;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 88px;
      text-align: left;
      padding: 0 0 0 134px;
      margin: 0;
    }

    footer img {
      margin: 10px 128px 0 0;
    }

    footer .logof {
      width: 63px;
      height: 63px;
      background-image: url('@/assets/images/logo_footer_big.png');
      background-position: center center;
      background-repeat: no-repeat;
      margin: 10px 128px 0 0;
      padding: 0;
      float: right;
    }
    .formulario {
      width: 360px;
      margin: 45px auto;
      background: #EAE7E4;
      border-radius: 6px;
      padding: 30px;
    }

  }
</style>

<script>

import useValidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import vueRecaptcha from 'vue3-recaptcha2';

import ElearningDataService from '../services/ElearningDataService';

import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
  name: 'RegistroView',
  components: {
    Loading,
    vueRecaptcha
  },
  methods: {
    cambiarModoPassword() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.modoPassword = !this.modoPassword;
    },
    submitForm() {
      this.v$.$validate() // checks all inputs
      this.validado = true;
      if (!this.v$.$error && this.recaptchaValidado) {
        this.isLoading = true;

        console.log("Registrando usuario."); 

        let data = new FormData;

        data.append('nombre', this.nombre);
        data.append('apellido_paterno', this.apellido_paterno);
        data.append('apellido_materno', this.apellido_materno);
        data.append('email', this.email);
        data.append('cedula', this.cedula);
        data.append('estado', this.estado);
        data.append('password', this.password);

        ElearningDataService.registrar(data)
          .then(response => {
            
            let error = response.data.error;

            if(error){
              this.$router.push('/registro_respuesta');
            }else{

              localStorage.nombre = this.nombre;
              localStorage.apellido_paterno = this.apellido_paterno;
              localStorage.apellido_materno = this.apellido_materno;
              localStorage.email = this.email;
              localStorage.cedula = this.cedula;
              localStorage.estado = this.estado;
              localStorage.loggedin = true;
              localStorage.uid = response.data.uid; 

              this.$router.push('/inicio');
            }

            this.isLoading = false;
                        
          })
          .catch(e => {
            console.log(e);
          });

      } else {
        //alert('Form failed validation')
      }
    },
    recaptchaVerified(response) {
      this.recaptchaValidado = true;
      console.log(response);
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
    },
    
    recaptchaError(reason) {
      console.log(reason);
    }
  },
  validations() {
    return {
      nombre: { required },
      apellido_paterno: { required },
      apellido_materno: { required },
      email: { required, email },
      cedula: { required },
      estado: { required },
      password: { required },
    }
  },
  data: () => ({
    v$: useValidate(),
    isLoading: false,
    validado: false,
    modoPassword: false,
    passwordFieldType: 'password',
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
    email: '',
    cedula: '',
    estado: '',
    password: '',
    recaptchaValidado: false,
    showRecaptcha: true,
		loadingTimeout: 30000 // 30 seconds
  })
}
</script>
