<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="logo-wrapper">
            <img src="@/assets/images/logo-micronutrientes-horizontal.png">
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="menu">
            <router-link to="/modulos" class="btn-inicio"><img src="@/assets/images/icon_home.png"></router-link>
            <router-link to="/perfil" class="btn-profile"><img src="@/assets/images/icon_profile.png"></router-link>
            <router-link to="/logout" class="btn-logout"><img src="@/assets/images/icon_sesion.png"></router-link>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="linea"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-7" style="">
          <div class="player-wrapper">
            <vue3-video-player @play="your_method" :src="ruta_video"></vue3-video-player>
          </div>
        </div>
        <div class="col-lg-5" style="background-color: #fff;">
          <div>
            
            <h2 class="titulo_curso">Título de curso:</h2>
            <h1 class="titulo_curso_2">Micronutrientes y etapa preconcepcional</h1>
            <h3 class="titulo_instrucciones">Instrucciones:</h3>

            <ul class="app-instructions">

              <li>
                <p>1. El curso cuenta con un total de 10 módulos.</p>
              </li>
              <li>
                <p>2. Para acceder a cada módulo se necesita finalizar el módulo previo.</p>
              </li>
              <li>
                <p>3. El curso consta de 16 puntos y tiene un valor curricular ante el Comité Normativo Nacional de Medicina General A.C. No. de Registro: 5515/2023</p>
              </li>
              <li>
                <p>4. Es necesario aprobar el curso en un 80% para lograr una puntuación curricular.</p>
              </li>
            </ul>

            <p class="duracion"><strong>Duración total del curso:</strong> 5 horas<br/><strong>Duración aproximada por módulo:</strong> 20 a 30 minutos</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-7 d-xl-block d-md-none d-none">
          
        </div>
        <div class="col-lg-5">
          <a href="javascript:;" @click="iniciar" class="btn-login" >Iniciar curso</a>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <div style="">
            <img src="@/assets/images/footer.png" style="width: 100%;">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <footer>
            <div class="row">
              <div class="col-lg-6 col-8" style="">
                <p>Copyright &copy; Bayer AG </p>
              </div>
              <div class="col-lg-6 col-4" style=" text-align: right;">
                <div class="logof"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>  
  </div>

  <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
</template>

<style scoped>

  div.menu {
    text-align: center;
    width: 100%;
    margin: 25px 0 0 0;
    display: flex;
    flex-flow: row;
  }

  div.menu a {
    height: 60px;
    line-height: 60px;
    flex: 1;
    text-align: center;
    display: block;
    width: 33%;
    flex-grow: 1;
  }

  div.menu a.btn-profile {
    margin: 0;
  }

  div.menu a.btn-logout {
    
  }

  div.linea {
    background: #C5C3C3;
    height: 2px;
    width: 100%;
    margin: 0;
  }

  .home .logo {
    margin: 30px auto 0 auto;
  }


  .logo-wrapper {
    height: 60px; 
    background-color: #fff; 
    text-align: center;
    padding: 15px ;
  }

  .logo-wrapper .img {
    margin: 15px 0 0 15px;
  }

  .home h1.app-title {
    font-size: 20px;
    color: #10384f;
    padding: 30px 15px;
    font-weight: bold;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61bb47;
    padding: 15px 15px;
  }

  .home .btn-start {
    border: 0;
    background-color: #fff;
    margin: 15px auto 0 auto;
  }

  ul.app-instructions {
    text-align: left;
    padding: 0 15px;
    list-style-position: inside;
    list-style: none;
  }

  ul.app-instructions li p{
    font-size:16px; 
    color:#5C5C5C;
    padding: 0 0 5px 0;
  }

  .app-cedula {
    width: 100%;
    background-color: #ededed;
    height: 59px;
    line-height: 59px;
    border-radius: 15px;
    border-color: transparent;
  }

  .vcp-container, .vcp-container video {
    width: 100%;
    height: 100%;
    background-color: #7bbc6e;
  }

  .vcp-dashboard {
    width: 100% !important;
    margin-left: 0 !important;
  }

  button.app-registro {
    width: 100%;
    height: 59px;
    line-height: 59px;
    background-color: #fff;
    color: #10384f;
    font-size: 22px;
    text-align: center;
    border-radius: 15px;
    border-color: transparent;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.54); 
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.54);
    margin: 30px 0 0 0;
  }

  button.app-registro span svg {
    margin: -5px 0 0 0;
    font-size: 26px;
  }

  
  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 15px auto 45px auto ;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  .btn-login:hover {
    border: 2px solid #0093D0;
    background: #FFF;
    color: #0093D0;
  }
 
 
  footer {
    width: 100%;
    height: 58px;
    background: #61BB47;
  }

  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    padding: 0 0 0 15px;
  }

  footer img {
    margin: 7px 25px 0 0;
  }

  footer .logof {
    width: 42px;
    height: 43px;
    background-image: url('@/assets/images/logo_footer_small.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 7px 25px 0 0;
    padding: 0;
    float: right;
  }

  .player-wrapper {
    height: 251px;
    padding: 15px;
  }

  .titulo_curso {
    text-align: left; 
    font-size:20px; 
    font-weight:bold; 
    color:#003366; 
    padding: 15px;
  }

  .titulo_curso_2 {
    text-align: left;
    font-size:20px; 
    font-weight:bold; 
    color:#003366;
    padding: 0 15px;
  }

  .titulo_instrucciones {
    text-align: left;
    font-size:16px; 
    font-weight:bold; 
    color:#5C5C5C; 
    padding: 15px;
  }

  p.duracion {
    text-align: left;
    font-size:16px; 
    color:#5C5C5C;
    padding: 0 15px;
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {  
  
  }

  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (min-width: 768px) {  
    .formulario {
      width: 360px;
      margin: 45px auto;
      background: #EAE7E4;
      border-radius: 6px;
      padding: 30px;
    }

    .player-wrapper {
      height: 440px;
      padding: 15px;
    }

    ul.app-instructions li p{
      font-size:16px; 
      color:#5C5C5C;
      padding: 0 0 15px 0;
    }

    div.menu {
      text-align: right;
      width: 100%;
      margin: 15px 0;
      display: block;
    }

    div.menu a {
      height: 60px;
      display: inline-block;
      line-height: 60px;
      width: auto;
    }

    div.menu a.btn-profile {
      margin: 0 60px;
    }

    .logo-wrapper {
      height: 60px; 
      background-color: #fff; 
      text-align: left;
      padding: 0 ;
      margin: 15px 0;
    }

    .logo-wrapper .img {
      margin: 15px 0 0 0;
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {  
      
  }

  /* Extra extra large devices (extra large desktops, 1400px and up) */
  @media (min-width: 1400px) {  
    .home .logo {
      margin: 15px auto 0 auto;
    }

    .home h1.app-title {
      font-size: 20px;
      color: #000;
      padding: 15px 0;
      font-weight: bold;
      margin: 0;
    }

    .home h2.app-description {
      font-size: 18px;
      color: #61BB47;
      padding: 15px 0;
      margin: 0;
    }

    .btn-login {
      display: block;
      width: 300px;
      height: 48px;
      padding: 10px;
      border-radius: 30px;
      background: #0093D0;
      text-align: center;
      color: #fff;
      border: 0;
      margin: 15px auto 45px auto;
      text-decoration: none;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      border: 2px solid #fff;
    }

    footer {
      width: 100%;
      height: 88px;
      background: #61BB47;
    }

    footer p {
      color: #FFF;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 88px;
      text-align: left;
      padding: 0 0 0 134px;
      margin: 0;
    }

    footer img {
      margin: 10px 128px 0 0;
    }

    footer .logof {
      width: 63px;
      height: 63px;
      background-image: url('@/assets/images/logo_footer_big.png');
      background-position: center center;
      background-repeat: no-repeat;
      margin: 10px 128px 0 0;
      padding: 0;
      float: right;
    }

    .formulario {
      width: 360px;
      margin: 45px auto;
      background: #EAE7E4;
      border-radius: 6px;
      padding: 30px;
    }

    .player-wrapper {
      height: 524px;
      padding: 0 0 0 15px;
      margin: 15px 0;
    }

    .titulo_curso {
      text-align: left; 
      font-size:20px; 
      font-weight:bold; 
      color:#003366; 
      padding: 30px 15px 0 15px;
    }

    .titulo_curso_2 {
      text-align: left;
      font-size:20px; 
      font-weight:bold; 
      color:#003366;
      padding: 15px 15px 0 15px;
    }

    .titulo_instrucciones {
      text-align: left;
      font-size:16px; 
      font-weight:bold; 
      color:#5C5C5C; 
      padding: 15px 15px 20px 15px;
    }

    p.duracion {
      text-align: left;
      font-size:16px; 
      color:#5C5C5C;
      padding: 0 15px;
    }

    .logo-wrapper {
      height: 60px; 
      background-color: #fff; 
      text-align: left;
      padding: 0 ;
      margin: 15px 0;
    }

    .logo-wrapper .img {
      margin: 15px 0 0 15px;
    }

    ul.app-instructions li p{
      font-size:16px; 
      color:#5C5C5C;
      padding: 0 0 15px 0;
    }
  }
</style>

<script>
// @ is an alias to /src

import Loading from 'vue3-loading-overlay';

import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';


export default {
  name: 'InicioView',
  components: {
    Loading
  },

  mounted(){

    
  },
  data: () => ({
    ruta_video: require('@/assets/videos/Bescience_videoCortoAdaptado3.mp4'),
    ci: '',
    regs: [],
    isLoading: false,
    terminado: false
  }),
  methods: {
    iniciar(){
      this.$router.push('/modulos');
    }
 
  }
}



</script>
