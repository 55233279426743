<template>
  <div class="home">
    <div class="container">
      <div class="row d-xl-flex d-md-flex d-none">
        <div class="col-lg-12">
          <div style="height: 40px; background-color: #fff;"></div>
        </div>
      </div>

      <div class="row">
       
        <div class="col-lg-12" style="background-color: #fff;">
          <div style="text-align: center;">
            <img src="@/assets/images/logo-micronutrientes-horizontal.png" class="logo">
           
            <div class="formulario">
              <p class="formulario-titulo">Recuperar contraseña</p>
              
              <p class="descripcion">Por favor, ingrese su correo electrónico<br/>registrado y le enviaremos una nueva<br/> contraseña a:</p>
              <div class="mb-3">
                <input type="email" class="form-control input-email" :class="{ error: v$.email.$errors.length, success: v$.email.$errors.length == 0 && validado }"  id="email" aria-describedby="emailHelp" placeholder="Correo electrónico" v-model="email">
              </div>

              <button class="btn-login" @click="submitForm">Solicitar nueva contraseña</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <div style="">
            <img src="@/assets/images/footer.png" style="width: 100%;">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <footer>
            <div class="row">
              <div class="col-lg-6 col-8" style="">
                <p>Copyright &copy; Bayer AG </p>
              </div>
              <div class="col-lg-6 col-4" style=" text-align: right;">
                <div class="logof"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>

    </div>  
  </div>
  <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
</template>

<style scoped>
  .home .logo {
    margin: 15px auto 0 auto;
  }

  .formulario {
    width: 100%;
    margin: 25px auto;
    background: #EAE7E4;
    border-radius: 6px;
    padding: 30px;
  }

  .formulario p.formulario-titulo {
    color: #28287C;
    text-align: center;
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .formulario input[type='email'], .formulario input[type='password'], .formulario input[type='text'] {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background: #F4F4F4;
    border: 2px solid #F4F4F4;
  }

  .formulario input[type='email'] {
    background-image: url('@/assets/images/icon_email.svg');
    background-position: 17px center;
    background-repeat: no-repeat;
    padding: 0 0 0 45px;
  }

 

  .formulario input.error, .formulario select.error, .formulario .recaptcha_wrapper.error {
    border: 2px solid #F41313;
  }
  


  .formulario input.error::placeholder {
    color: #F41313;
    font-style: italic;
  }

  .formulario input.success, .formulario select.success, .formulario .recaptcha_wrapper.success {
    border: 2px solid #61BB47;
  }

  .formulario input.success::placeholder {
    color: #61BB47;
  }

  p.descripcion {
    color: #5A5857;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }

  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 45px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #0093D0;
  }

  .btn-login:hover {
    border: 2px solid #0093D0;
    background: #FFF;
    color: #0093D0;
  }
 
  p.recuperar {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 35px 0 0 0;
  }

  p.recuperar a {
    color: #003AE6;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
  }

  footer {
    width: 100%;
    height: 58px;
    background: #61BB47;
  }


  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    padding: 0 0 0 15px;
    margin: 0;
  }

  footer img {
    margin: 7px 25px 0 0;
  }

  footer .logof {
    width: 42px;
    height: 43px;
    background-image: url('@/assets/images/logo_footer_small.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 7px 25px 0 0;
    padding: 0;
    float: right;
  }
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {  
  
  }

  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (min-width: 768px) {  
    .formulario {
      width: 360px;
      margin: 45px auto;
      background: #EAE7E4;
      border-radius: 6px;
      padding: 30px;
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {  
      
  }

  /* Extra extra large devices (extra large desktops, 1400px and up) */
  @media (min-width: 1400px) {  
    .home .logo {
      margin: 15px auto 0 auto;
    }

    .home h1.app-title {
      font-size: 20px;
      color: #000;
      padding: 15px 0;
      font-weight: bold;
      margin: 0;
    }

    .home h2.app-description {
      font-size: 18px;
      color: #61BB47;
      padding: 15px 0;
      margin: 0;
    }

    footer {
      width: 100%;
      height: 88px;
      background: #61BB47;
    }

    footer p {
      color: #FFF;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 88px;
      text-align: left;
      padding: 0 0 0 134px;
      margin: 0;
    }

    footer img {
      margin: 10px 128px 0 0;
    }

    footer .logof {
      width: 63px;
      height: 63px;
      background-image: url('@/assets/images/logo_footer_big.png');
      background-position: center center;
      background-repeat: no-repeat;
      margin: 10px 128px 0 0;
      padding: 0;
      float: right;
    }
    .formulario {
      width: 360px;
      margin: 45px auto;
      background: #EAE7E4;
      border-radius: 6px;
      padding: 30px;
    }

  }
</style>

<script>

import useValidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import ElearningDataService from '../services/ElearningDataService';

import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
  name: 'RecuperarView',
  components: {
    Loading
  },
  methods: {
    
    submitForm() {
      this.v$.$validate() // checks all inputs
      this.validado = true;
      if (!this.v$.$error) {
        
        this.isLoading = true;

        let data = new FormData;

        data.append('email', this.email);

        ElearningDataService.recuperar(data).then(response => {
            
          this.isLoading = false;
          let error = response.data.error;

          if(error){
            this.$router.push('/recuperar_respuesta');
          }else{
            this.$router.push('/recuperar_respuesta');
          }

          this.isLoading = false;
        }).catch(e => {
          console.log(e);
        });
      } else {
        //alert('Form failed validation')
      }
    },
  },
  validations() {
    return {
      email: { required, email },
    }
  },
  data: () => ({
    v$: useValidate(),
    validado: false,
    email: '',
    isLoading: false,
  })
}
</script>
