<template>
  <div class="home">
    <div class="container">
      <div class="row d-xl-flex d-none">
        <div class="col-lg-12">
          <div style="height: 40px; background-color: #fff;"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-7 d-xl-block d-md-block d-none">
          <div style="height: 535px;">
            <Carousel :autoplay="2000" :wrapAround="true">
              <Slide v-for="slide in slides" :key="slide">
                <div class="carousel__item"  v-bind:style="{ backgroundImage: 'url(' + slide.url + ')' }"></div>
              </Slide>

              <template #addons>
                <Navigation />
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
        <div class="col-lg-5" style="background-color: #fff;">
          <div style="text-align: center;">
            <img src="@/assets/images/bayer_elevit_logo.png" class="logo">
            <h1 class="app-title d-xl-block d-md-none d-none">Bienvenida(o) al curso en línea<br/>de Micronutrientes y etapa preconcepcional</h1>
            <h1 class="app-title d-xl-none d-md-block  d-none">Bienvenida(o) al curso en líneade Micronutrientes<br/> y etapa preconcepcional</h1>
            <h1 class="app-title d-xl-none d-md-none d-block">Bienvenida(o) al curso en línea<br/>de Micronutrientes y etapa<br/> preconcepcional</h1>

            <h2 class="app-description d-xl-block d-md-none d-none">Este curso busca compartir los conocimientos<br/>actuales sobre la importancia de la nutrición en el periodo<br/>preconcepcional y los resultados de la misma<br/>durante el embarazo y la lactancia.</h2>
            <h2 class="app-description d-xl-none d-md-block d-none">Este curso busca compartir los conocimientos actuales sobre la<br/>importancia de la nutrición en el periodo preconcepcional y los<br/>resultados de la misma durante el embarazo y la lactancia.</h2>
            <h2 class="app-description d-xl-none d-md-none d-block">Este curso busca compartir los<br/>conocimientos actuales sobre la importancia<br/>de la nutrición en el periodo preconcepcional<br/>y los resultados de la misma durante el<br/>embarazo y la lactancia.</h2>

            
            <router-link to="/login" class="btn-login">Iniciar sesión</router-link>

            <router-link to="/registro" class="btn-registro">Registrarse</router-link>
            <p class="recuperar">¿Ha olvidado su contraseña? <router-link to="/recuperar" class="btn-recuperar">Recuperar aquí </router-link></p>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <div style="">
            <img src="@/assets/images/footer.png" style="width: 100%;">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" style="">
          <footer>
            <div class="row">
              <div class="col-lg-6 col-8" style="">
                <p>Copyright &copy; Bayer AG </p>
              </div>
              <div class="col-lg-6 col-4" style=" text-align: right;">
                <div class="logof"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>

    </div>  
  </div>
</template>

<style scoped>
  .home .logo {
    margin: 30px auto;
  }

  .home h1.app-title {
    font-size: 22px;
    color: #000;
    padding: 15px 0;
    font-weight: bold;
    margin: 0;
  }

  .home h2.app-description {
    font-size: 16px;
    color: #61BB47;
    padding: 15px 0;
    margin: 0;
  }

  .home .btn-start {
    border: 0;
    background-color: #fff;
    margin: 15px auto 0 auto;
  }

  .btn-login {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #0093D0;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 15px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  .btn-login:hover {
    border: 2px solid #0093D0;
    background: #FFF;
    color: #0093D0;
  }
  .btn-registro {
    display: block;
    width: 300px;
    height: 48px;
    padding: 10px;
    border-radius: 30px;
    background: #61BB47;
    text-align: center;
    color: #fff;
    border: 0;
    margin: 15px auto 0 auto;
    text-decoration: none;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border: 2px solid #fff;
  }

  .btn-registro:hover {
    border: 2px solid #0093D0;
    background: #FFF;
    color: #0093D0;
  }
  p.recuperar {
    color: #5C5C5C;
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 15px 0 0 0;
  }

  p.recuperar a {
    color: #003AE6;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
  }

  footer {
    width: 100%;
    height: 58px;
    background: #61BB47;
  }

  footer p {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
    padding: 0 0 0 15px;
    margin: 0;
  }

  footer img {
    margin: 7px 25px 0 0;
  }

  footer .logof {
    width: 42px;
    height: 43px;
    background-image: url('@/assets/images/logo_footer_small.png');
    background-position: center center;
    background-repeat: no-repeat;
    margin: 7px 25px 0 0;
    padding: 0;
    float: right;
  }
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {  
  
  }
 
  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (min-width: 768px) {  
  
  }
 
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 
  
  }
 
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {  
      
  }
 
  /* Extra extra large devices (extra large desktops, 1400px and up) */
  @media (min-width: 1400px) {  
    .home .logo {
      margin: 15px auto 0 auto;
    }

    .home h1.app-title {
      font-size: 20px;
      color: #000;
      padding: 15px 0;
      font-weight: bold;
      margin: 0;
    }

    .home h2.app-description {
      font-size: 18px;
      color: #61BB47;
      padding: 15px 0;
      margin: 0;
    }

    footer {
      width: 100%;
      height: 88px;
      background: #61BB47;
    }

    footer p {
      color: #FFF;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 88px;
      text-align: left;
      padding: 0 0 0 134px;
      margin: 0;
    }

    footer img {
      margin: 10px 128px 0 0;
    }

    footer .logof {
      width: 63px;
      height: 63px;
      background-image: url('@/assets/images/logo_footer_big.png');
      background-position: center center;
      background-repeat: no-repeat;
      margin: 10px 128px 0 0;
      padding: 0;
      float: right;
    }
  }
</style>

<style>
  .carousel__item {
    height: 535px;
    width: 100%;
    background-position: center center;
    background-size: cover;
  }

  .carousel__pagination {
    display: none;
  }

  .carousel__next {
    transform: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 100%;
    right: 10px;
  }

  .carousel__prev {
    transform: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 100%;
    left: 10px;
  }
</style>
<script>
// @ is an alias to /src
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';


export default {
  name: 'HomeView',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  mounted(){
    if(localStorage.loggedin && localStorage.uid){
      console.log("Usuario con sesión activa");
      this.$router.push('/inicio');
    }else{
      console.log("Usuario sin sesión activa");
    }
  },
  data: () => ({
    slides: [
      {url: require('@/assets/images/slider1.jpg')},
      {url: require('@/assets/images/slider2.jpg')},
      {url: require('@/assets/images/slider3.jpg')},
      {url: require('@/assets/images/slider4.jpg')},
    ]
  })
}
</script>
