<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div style="height: 60px; background-color: #fff; border-bottom: 1px solid #ccc; text-align: left;">
            <img src="@/assets/images/logo-header.png" style="margin: 5px 0 0 15px;">
          </div>
        </div>
      </div>

      <div class="row" v-if="terminado">
        <div class="col-lg-10 offset-1" style="background-color: #fff;">
          <div style="width: 100%; text-align: center;">
            <img src="@/assets/images/logo-fin.png" style="margin: 30px 0 0 0;">
            <p style="color:#10384f; font-size: 22px; font-weight: bold; padding: 30px 0 0 0;">¡Felicidades, usted ha concluido exitosamente el Curso de Micronutrientes y Etapa Preconcepcional!</p>
            <p style="color:#61bb47; font-size: 18px;">Le invitamos a descargar su constancia, dando clic en el botón "Descargue su constancia",<br/>donde deberá colocar su nombre completo (nombre(s) y los dos apellidos)<br/>como requisito exigido por el Consejo Mexicano de Ginecología y Obstetricia A.C.,<br/>para otorgar los puntos de certificación.<br/>Muchas gracias por su participación.</p>

          </div>
        </div>
      </div>

      <div class="row" v-if="terminado">
        <div class="col-lg-10 offset-1" style="background-color: #fff;">
          <div class="row">
            
            <div class="col-lg-6 offset-3">
              
              <button @click="descargarDiploma" class="app-descargar-terminado">descargue su constancia <span><BIconFileEarmarkArrowDown/></span></button>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div style="width: 100%; height: 8px; background-color: #00a3ce;"></div>
        </div>
      </div>
    </div>  
  </div>
  <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
</template>

<style scoped>
  .home .logo {
    margin: 30px auto 0 auto;
  }

  .home h1.app-title {
    font-size: 22px;
    color: #10384f;
    padding: 30px 0;
    font-weight: bold;
  }

  .home h2.app-description {
    font-size: 18px;
    color: #61bb47;
    padding: 15px 0;
  }

  .home .btn-start {
    border: 0;
    background-color: #fff;
    margin: 15px auto 0 auto;
  }

  ul.app-instructions {
    text-align: left;
    padding: 0;
    list-style-position: inside;
    list-style: none;
  }

  ul.app-instructions li p{
    text-align: left;
  }

  .app-cedula {
    width: 100%;
    background-color: #ededed;
    height: 59px;
    line-height: 59px;
    border-radius: 15px;
    border-color: transparent;
  }

  .vcp-container, .vcp-container video {
    width: 100%;
    height: 100%;
    background-color: #7bbc6e;
  }

  .vcp-dashboard {
    width: 100% !important;
    margin-left: 0 !important;
  }

  button.app-registro {
    width: 100%;
    height: 59px;
    line-height: 59px;
    background-color: #fff;
    color: #10384f;
    font-size: 22px;
    text-align: center;
    border-radius: 15px;
    border-color: transparent;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.54); 
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.54);
    margin: 30px 0 0 0;
  }

  button.app-registro span svg {
    margin: -5px 0 0 0;
    font-size: 26px;
  }

    button.app-descargar-terminado {
    width: 100%;
    height: 59px;
    line-height: 59px;
    background-color: #fff;
    color: #10384f;
    font-size: 22px;
    text-align: center;
    border-radius: 15px;
    border-color: transparent;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.54); 
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.54);
    margin: 50px 0 50px 0;
    text-transform: uppercase;
  }

  button.app-descargar-terminado span svg {
    margin: -5px 0 0 0;
    font-size: 26px;
  }
</style>

<script>
// @ is an alias to /src
import { BIconFileEarmarkArrowDown } from 'bootstrap-icons-vue';
import Loading from 'vue3-loading-overlay';

import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

import ElearningDataService from '../services/ElearningDataService';

export default {
  mounted(){
    this.verificarTerminado();
  },
  name: 'TerminadoView',
  components: {   
    BIconFileEarmarkArrowDown, 
    Loading
  },

  data: () => ({
    ci: '',
    regs: [],
    isLoading: false,
    terminado: false,
  }),
  methods: {
     
    descargarDiploma(){
        let uid = parseInt(this.$route.params.uid);
        
          this.isLoading = true;

          let _this = this;

          let data = new FormData;

          data.append('uid', uid);

          ElearningDataService.descargarDiploma(data).then(response => {
            console.log(response.data);

            _this.isLoading = false;

                var FILE = window.URL.createObjectURL(new Blob([response.data]));
                     
                     var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', 'Constancia.pdf');
                     document.body.appendChild(docUrl);
                     docUrl.click();

          })
          .catch(e => {
            console.log(e);
          });
        
     },

     verificarTerminado(){
       let uid = parseInt(this.$route.params.uid);
        
          this.isLoading = true;

          let _this = this;

          let data = new FormData;

          data.append('uid', uid);

          ElearningDataService.verificarTerminado(data).then(response => {
            console.log(response.data);
            _this.isLoading = false;

            if(response.data.terminado){
              _this.terminado = true;
            }else{
              _this.terminado = false;
            }
          })
          .catch(e => {
            console.log(e);
          });
     }
  }
}



</script>
